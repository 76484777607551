import { Link } from 'react-router-dom';
import $ from "jquery";
import { useEffect } from 'react';
import CareerForm from './CareerForm';
import { useRef } from "react";

const CareerBanner = () => {

    const formcar = useRef(null);

    useEffect(() => {
        $('.arrow-btn').on('click', function () {

            if ($(this).parent().parent().children().hasClass('list-style-block')) {
                $(this).parent().parent().children().removeClass('list-style-block');
                $(this).removeClass('down-arrow');
            }
            else {
                let a = $(this).parent().parent();
                $(a).children(".list-style").addClass('list-style-block');
                $(a).children(".applynow").addClass('list-style-block');
                $(this).addClass('down-arrow');
            }

        });

    }, []);

    const ScrollForm = () => {
        formcar.current.scrollIntoView();
    }


    return (
        <>
            <div className="careertitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Career</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Career</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container mt-5 mb-5 careerphara'>
                <p>
                    The work culture at Pump Academy is designed to challenge individuals, to deliver opportunities, and to reward performance and achievements with recognition. This shall enable the Company to build a world-class working environment which maximises the potential of its greatest assets – its human resources.
                </p>
                <p>
                    Pump Academy aims to onboard exceptional people regardless of gender, religion, caste or community and provide them with an extraordinary work environment. The only critical paths to advancement are merit, honesty, diligence and commitment.
                </p>
                <p>
                    Pump Academy offers several opportunities for Software engineers, Hardware Engineers, ITI Diploma Holders, fresh graduates as well as experienced professionals from diverse industries and backgrounds.
                </p>
            </div>


            <div className='listingjobs'>
                <div className='container open-position'>
                    <h3 className='text-center'>
                        Current Openings
                    </h3>
                </div>

                <div className='container mt-4 mb-5'>
                    <div className='position-head col-md-12'>
                        <h4>UI Programmer</h4><h6>Engineering Degree in Computer Science<br />2-4 Years of experience
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h6>
                        <ul className='list-style'>
                            <li>4 years of experience in HTML, JavaScript, JSON, and CSS (Boot Strap)</li>
                            <li>A minimum of 2 years of experience in React JS out of the total of 4 years</li>
                            <li>Experience in React Native is an added advantage</li>
                            <li>Knowledge of .NET Core C# for API development is an added advantage</li>
                        </ul>
                        <div className='clearfix'></div>
                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>
                    </div>

                    <div className='position-head col-md-12'>
                        <h4>Microsoft SQL Server Programmer</h4><h6>Engineering Degree in Computer Science<br />3-5 Years of experience
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h6>
                        <ul className='list-style'>
                            <li>Engineering Degree in Computer Science</li>
                            <li>Minimum of 3 to 5 years of experience in Microsoft SQL Server 2017 or above</li>
                            <li>Must have full knowledge of using JSON with Microsoft SQL Server</li>
                            <li>Fully conversant with stored procedures and functions</li>
                            <li>Knowledge of .NET Core C# for API development is an added advantage</li>
                        </ul>
                        <div className='clearfix'></div>
                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>
                    </div>

                    <div className='position-head col-md-12'>
                        <h4>Jr Embedded Engineer</h4><h6>BE/Btech (EC/ETC/EE) Degree in a technical field is required<br />0-2 Years of experience
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h6>
                        <ul className='list-style'>
                            <li>Exposure to Microcontroller and Microprocessor based embedded projects.</li>
                            <li>Knowledge of 8/16/32 bit microcontrollers (PIC, STM, ARM etc) architecture and programming.</li>
                            <li>Should know Embedded C and other programming languages like Python.</li>
                            <li>Understanding of Protocols like I2C, SPI, RS485, Modbus & Ethernet is required.</li>
                            <li>Knowledge on lab equipment including oscilloscope, signal generators, Logic analyzer, debugging tools and similar test apparatus.</li>
                            <li>Knowledge in PCB design software like OrCAD & KICAD is added advantage.</li>
                            <li>In this role, you will be part of product development team which involves designing, developing, coding, testing, and debugging embedded system.</li>
                            <li>Responsible to test and collect the data from installed devices which are located remotely.</li>
                            <li>Responsible for creating technical documentation such as installation manual and user guides.</li>
                        </ul>
                        <div className='clearfix'></div>
                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>
                    </div>

                    <div className='position-head mt-4 col-md-12'>
                        <h4>Embedded Engineer</h4><h6>BE/Btech (EC/ETC/EE) Degree in a technical field is required<br />2-4 Years of experience
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h6>
                        <ul className='list-style'>
                            <li>Have an exposure to complete life cycle of embedded product development from BOQ to Final Product.</li>
                            <li>Hands-on experience with 8-bit,16-bit and 32-bit microcontrollers development environment and programming.</li>
                            <li>Hands-on experience on PCB design using applications like OrCad and Pads.</li>
                            <li>Extensive experience in Communication interfaces like I2C, SPI, UART and Modbus.</li>
                            <li>Knowledge of Wired/wireless Protocols/technologies like Ethernet, Wi-Fi, ZigBee and BLE.</li>
                            <li>Worked on signal processing at sub-GHz level.</li>
                            <li>Familiar with testing tools like logic analyzer, oscilloscope and debugging tools.</li>
                            <li>Knowledge of EMI/EMC/IPXX testing procedures.</li>
                            <li>Knowledge of Industry 4.0, 5.0 technologies like Industrial Internet of Things Standards (IIOT).</li>
                            <li>In this role, you will be part of product development team which involves designing, developing, coding, testing, and debugging embedded system.</li>
                            <li>Responsible to plan the optimal power consumption of the embedded system.</li>
                            <li>Responsible to ensure the product design which fulfil the appropriate standards and certifications.</li>
                            <li>Optimize BOM cost without compromising on quality and functionality of the product.</li>
                        </ul>
                        <div className='clearfix'></div>
                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>
                    </div>

                    <div className='position-head mt-4 col-md-12'>
                        <h4>Microsoft Azure technical lead
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h4>

                        <ul className='list-style'>
                            <li>Cloud environment design and full stack development.</li>
                            <li>Work experience in Python and .Net core-based APPs development.</li>
                            <li>Work experience with Azure services and Azure ML platform.</li>
                            <li>Work experience with minimum three medium scale live project design to product installation</li>
                            <li>Code level implementation of connectivity security up to level 5 or higher.</li>
                            <li>Work experience with Docker Containerization and Micro services based application development.</li>
                            <li>Strong problem solving skills, with an emphasis on target product development.</li>
                            <li>Knowledge and experience in real time predictive algorithms.</li>
                            <li>Knowledge of advanced statistical techniques and concepts like (regression, properties of distributions, statistical tests and proper usage, etc.).</li>
                            <li>Work experience in IoT domain.</li>
                            <li>A drive to learn and master new technologies, techniques, and Team player.</li>
                            <li>Work experience in RDBMS (MYSQL or SQL SERVER) and NOSQL (Mongo DB)</li>
                            <li>Should have hands on experience with deploying ML models using Docker/Kubernetes on Azure cloud.</li>
                            <li>Strong in Python, Linux, Windows OS and efficient port handling capabilities.</li>
                            <li>Good Communication and Presentation Skills.</li>
                            <li>Bachelor or Master’s degree with minimum 7 years’ of experience with end to end product development and launching experience</li>
                        </ul>
                        <div className='clearfix'></div>

                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>

                    </div>

                    <div className='position-head mt-4 col-md-12'>
                        <h4>Hardware design Lead
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h4>

                        <ul className='list-style'>
                            <li>Having minimum 10 plus years of experience in designing hardware boards up to 8/12 layers.</li>
                            <li>Hand on experience in identifying all the components and ICs</li>
                            <li>All the design passes the CE and FCC certification norms in first revision of the hardware.</li>
                            <li>PCB design expertise with current flow up to 200A.</li>
                            <li>Experience in handing sensor data at Micro and Pico seconds</li>
                            <li>Experience in RT OS ( free or other)</li>
                            <li>Experience in 16 bit Micro controller and 32 bit ARM processor programing</li>
                            <li>Hands on experience in hardware bit exactness, trouble shooting at GHz range.</li>
                            <li>Having experience in designing minimum three hardware products which are commercialized.</li>
                            <li>Good Communication and Presentation Skill.</li>
                            <li>Bachelor or master degree with minimum 10 years’ experience.</li>
                        </ul>
                        <div className='clearfix'></div>

                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>

                    </div>

                    {/* <div className='position-head mt-4 col-md-12'>
                        <h4>Microsoft cloud platform developer
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h4>
                        <ul className='list-style'>
                            <li>Having minimum two-project development experience in working on Azure cloud platform.</li>
                            <li>Python and .Net core based APPs development</li>
                            <li>Good exposure to Linux Kernel, Windows OS</li>
                            <li>Work experience with higher level security implementation</li>
                            <li>Work experience with Docker Containerization and Micro services based application development.</li>
                            <li>Strong problem-solving skills with an emphasis on product development.</li>
                            <li>Work experience in IOT based cloud service development.</li>
                            <li>A drive to learn and master new technologies, techniques, and Team player.</li>
                            <li>Work experience in RDBMS (MYSQL or SQL SERVER) and NOSQL (Mongo Db)</li>
                            <li>Good Communication and Presentation Skills.</li>
                            <li>Bachelor or master degree with minimum 1-2 years experience.</li>
                        </ul>
                        <div className='clearfix'></div>
                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>
                    </div> */}

                    {/* <div className='position-head mt-4 col-md-12'>
                        <h4>Software Engineer
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h4>
                        <ul className='list-style'>
                            <li>Designing and Programming in .NET web framework</li>
                            <li>VB/C#, Java Script, Familiarity with Microsoft SQL Server</li>
                            <li>Minimum Qualification: Bachelor ( BE – CS, IS/ E &C) or master degree (MCA/ M.Tech/ M.Sc IT) with 0-6 months experience</li>
                        </ul>
                        <div className='clearfix'></div>
                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>
                    </div> */}


                    <div className='position-head mt-4 col-md-12'>
                        <h4>Hardware design engineer
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h4>

                        <ul className='list-style'>
                            <li>Having minimum two plus years of experience in designing hardware boards of any layers.</li>
                            <li>Experience in writing cycle accurate micro controller assembly and C programs.</li>
                            <li>Hand on testing experience in handling GHz ADC input data</li>
                            <li>Experience in design and developing power amplifiers.</li>
                            <li>Experience in few wireless protocols</li>
                            <li>Hand on experience in identifying all the components and ICs</li>
                            <li>Good Communication and Presentation Skills.</li>
                            <li>Bachelor or master degree with minimum 2 years industrial experience.</li>
                        </ul>
                        <div className='clearfix'></div>

                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>

                    </div>


                    <div className='position-head mt-4 col-md-12'>
                        <h4>Software / Hardware associate engineer
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h4>

                        <ul className='list-style'>
                            <li>Having minimum 6 months industrial experience in hardware and software development.</li>
                            <li>Programming skills assembly, C or C++, Java and experience in webserver.</li>
                            <li>Experience in software module development and unit testing</li>
                            <li>Good Communication and Presentation Skill</li>
                            <li>Bachelor or master degree with minimum 6-month industrial experience.</li>
                        </ul>
                        <div className='clearfix'></div>

                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>

                    </div>


                    {/* <div className='position-head mt-4 col-md-12'>
                        <h4>Software Programmer
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h4>
                        <ul className='list-style'>
                            <li>Well Experience & Strong in Designing and Programming in .NET web framework.</li>
                            <li>Development, Support and Enhancement of existing web applications in .NET</li>
                            <li>Knowledge / worked on Android will be added advantage</li>
                            <li>Any with CSE/IT background.</li>
                            <li>4 year and + Experience</li>
                        </ul>
                        <div className='clearfix'></div>
                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>
                    </div> */}

                    <div className='position-head mt-4 col-md-12'>
                        <h4>Jr Industrial Automation & Control Engineer</h4><h6>BE / Diploma – Electrical / Electronics / Mechatronics<br />0-2 Years of experience
                            <Link className='arrow-btn' to='/careers'><i className="fal fa-arrow-right"></i></Link>
                        </h6>

                        <ul className='list-style'>
                            <li>Excellent Industrial Automation & Control Engineering knowledge, Including Electrical & Control Detail Engineering.</li>
                            <li>Worked with System Integrators or involved with Installation and Commissioning of Sensors network for Water pumping station/Hydro projects.</li>
                            <li>Debugging Hardware & Software on Site.</li>
                            <li>Design And Selection of Control System (IIOT/PLC and Networking).</li>
                            <li>Control System Architecture</li>
                            <li>Design Basis and Specification.</li>
                            <li>Input / Output List, Signal Exchange List / Communication List</li>
                            <li>Factory Acceptance Test Procedure and Site Acceptance Test Procedure</li>
                            <li>Intrinsic Safety Calculation</li>
                            <li>Familiar with Panel GA Drawing and Panel Wiring Diagram</li>
                            <li>Onsite Experience in Commissioning & Debugging of Control Systems</li>
                            <li>Onsite deployment of Software, Testing & Commissioning</li>
                            <li>Coordinate with Development Team and reporting</li>
                            <li>Co-ordinate with Customer for Project delivery and Quality</li>
                            <li>Relationship building with Clients for business expansion</li>
                        </ul>
                        <div className='clearfix'></div>

                        <Link to="/careers" className='applynow' onClick={ScrollForm}>Apply Now</Link>

                    </div>

                </div>
            </div>

            <div ref={formcar}>
                <CareerForm />
            </div>

        </>
    )
}

export default CareerBanner;