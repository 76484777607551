

const DeployFaster = () => {

    return (
        <>
            <div className="deploy-faster">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>iPUMPNET - Real time monitoring to analytics for enhancing the performance of pumping station</h1>
                        </div>

                        <div className="col-md-12">
                            <div className="box-info">
                                <ul>
                                <li>
                                        <img className='img-fluid' src="images/acquire.webp" />
                                        <p>iPUMPNET allows the user to access real-time pump health data and detect and predict the risk of pump failures.</p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src="images/analytics.webp" />
                                        <p>iPUMPNET aggregates data from all pumps to provide central monitoring, historical data access, reporting, and analytics. It enables centralized collection of historical data and trend analysis of the pumping station assets to improve deep learning analytics and reporting.</p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src="images/dynamic-performance.webp" />
                                        <p>iPUMPNET analyzes the dynamic performance of a pumping station by interlinking the operational parameters of electrical, mechanical, and hydraulic disciplines to understand the overall performance of the pumping station.</p>
                                    </li>

                                    <li>
                                        <img className='img-fluid' src="images/pump-performance.webp" />
                                        <p>Identifies and interprets the root cause for the change in pump performance from the calibrated base line conditions.</p>
                                    </li>


                                    
                                    <li>
                                        <img className='img-fluid' src="images/apps.webp" />
                                        <p>iPUMPNET is a collaboration platform that integrates pump stations, tools, data, and people for efficient decision making and operation of pumping stations.</p>
                                    </li>


                                    <li>
                                        <img className='img-fluid' src="images/real-time.webp" />
                                        <p>iPUMPNET is a secure and real-time monitoring and reporting solution for pumping sets installed anywhere and provides all the information in one place through an easy-to-use dashboard interface, which can be accessed via web or mobile application.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeployFaster;