import { Link } from "react-router-dom";

const PrecisionMonitoring=()=>{


    return(
        <>
          <div className="precision-monitoring">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12 col-lg-6 order-sm-2 order-xs-1 order-md-1">
                        <div className="precision-inf">
                          <h3>About us</h3>
                          <p>
                          One estimate states that electric motors account for 40% of global electric power usage (Source: International Energy Agency). Inefficient pumping station operation and maintenance can be caused by poor system design, pump selection, installation, and lack of real-time performance data, leading to excessive energy consumption, high operating and maintenance costs, and breakdowns. A comprehensive solution comprising of control, realtime monitoring, management, operation and maintenance is crucial for optimizing energy consumption and ensuring sustainability in water pumping systems.
                          <br /><br />
                          Over time, Mr. Anil Sethi and Mr. Deepak Sethi became interested in ways to improve the performance, reduce energy consumption, and lower costs of pumping stations. They both have extensive experience in setting up, operating, and maintaining over 5000 pumping stations.
                          </p>
                          <Link to="/about">Read More</Link>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 order-sm-1 order-xs-1 order-md-2">
                        <img className='img-fluid' src="images/pumpnet.webp" />
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default PrecisionMonitoring;