
const AboutPhara = () => {


    return (
        <>
       

            <div className="precision-monitoring about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <img className='img-fluid' src="images/pumpnet-about.webp" />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="precision-inf">
                                <h3>About us</h3>
                                <p>
                                Water pumping systems and rotor dynamic machines have become increasingly important due to growing concerns about avoidable  excessive energy consumption. According to estimates, while large motors with a capacity above 375 kW make up only a small percentage (0.03%) of all motors in use, they are responsible for consuming around 21% of total power. Factors such as poor system selection, improper design, excessive bearing clearances, and unnecessary flow control practices lead to high energy consumption, maintenance requirements, and inefficiency in system operation.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="precision-inf">
                            <div className="col-md-12">
                                <p>
                                Pump Academy is committed to creating a more sustainable and energy-efficient world through its innovative product, "iPUMPNET." It is designed to tackle specific issues that result in high energy consumption, avoidable expenses, and maintenance needs. By using the iPUMPNET solution, organizations can decrease their energy usage, lower maintenance costs and unexpected breakdown , reduce their carbon footprint, extend the life of pumps and machines, increase profitability, and contribute to steady and sustainable development.
                                </p>
                                <p>The Product vision, design, and implementation team at Pump Academy Private Limited is composed of a senior team of experts with over 200 years of combined Industrial experience. Each team member has over 20 years of experience in project design and project management, both in India and internationally in countries such as Europe, the United States, Japan, and Taiwan. The team's expertise in hardware, hybrid software, and application development allows them to handle all aspects of the commercial-grade product design, development, manufacturing, and market release process, including certifications from CE, FCC and FDA.
                                </p>
                                
                            </div>
                        </div>
                    </div>


                    <div className="vision px-5">
                        <div className="row">
                            <div className="col-md-6 py-5">
                                <div className="vision-mission text-center">
                                    <img className='img-fluid' src="images/vision.webp" />
                                    <h3>Vision</h3>
                                    <h5><i>Energizing a sustainable green world.</i></h5>
                                </div>
                            </div>
                            <div className="col-md-6 py-5">
                            <div className="vision-mission text-center">
                                <img className='img-fluid' src="images/mission.webp" />
                                <h3>Mission</h3>
                                <h5> </h5>
                                <p>To be a trusted sustainability partner creating valued, single-sourced, efficient energy saving and operation & Maintenance solutions for greener world delivered with passion, expertise, teamwork and a relentless focus on customer satisfaction.</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutPhara;