const IpumpnetInfo=()=>{

    return(
        <>
           <div className="pumpnet-description">
               <div className="container">
                   <div className="row align-items-center">
                       <div className="col-md-12 col-lg-6 text-center">
                          <div className="machine-learning">
                             <img src="images/machine-learning.webp" className="img-fluid" />
                          </div>
                       </div>
                       <div className="col-md-12 col-lg-6">
                           <div className="phara-info">
                           <img className="mb-3" src="images/pumpnet-logo.webp" />
                           <p>
                           is a complete web and mobile-based solution for managing the performance of pumping stations that combines advanced analytics platforms using digital twins and machine learning-based artificial intelligence. It also includes basic metering and high-precision web of IoT sensors for data acquisition. iPUMPNET is a centralized, connected platform that integrates various aspects of effective pumping stations, including pumps, motors, sensors and human factors. The iPUMPNET solution aims to optimize the performance and efficiency of electric motor-driven pumping stations, resulting in cost savings, reducing unscheduled outages, improved services to citizens and steady and sustainable growth.</p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </>
    )
}

export default IpumpnetInfo;