//import InputHTMLAttributes  from "react";
//import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { useRef } from "react";

const CareerForm = () => {

    const API_PATH = 'https://www.pumpacademy.in/careerform.php';

    const [message, setMessage] = useState({
        sent: '',
        mess: ''
    });

    const [img, setImg] = useState();
    const [state, setState] = useState();

    let initialValues = {
        name: '',
        lname: '',
        email: '',
        dapartment: '',
        phone: '',
        position: '',
        message:'',
        document: '',
        file: null,
    };
    
    const validationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required('Name is required')
                .min(4, 'Name must be at least 3 characters'),
            email: Yup.string()
                .required('Email is required')
                .email('Email is invalid'),
            dapartment: Yup.string()
                .required('Department is required')
                .min(3, 'Company Name must be at least 4 characters')
                .max(40, 'Company Name not exceed 40 characters'),
            position: Yup.string()
                .required('Position is required'),
            phone: Yup.number()
                .required('A phone number is required')
                .min(10),
        });
    }

    const HandleChange = (e) => {
        setImg(e.target.files[0].name);
        //console.log(e.target.files[0]);
        setState(e.target.files[0]);
    }

    //file upload code starts here
    //file upload code ends here

    const handleSubmit = (data, { resetForm }) => {        

        //console.log(img);
        data.document = img;
        
        //Create an object of formData
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key])
          })
 
        //Update the formData object
        formData.append(
            "file",
            state
        );

        //console.log(formData);

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        })
            .then(result => {
                setMessage({
                    sent: result.data.sent,
                    mess: result.data.message
                })
                console.log(result);
                toast.success("Thank you for submitting enquiry!!");
                resetForm();
            })
            .catch(error => setMessage(error.sent));      
    }

    return (
        <>
            <div className="careerform pt-5 pb-5">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-md-12 col-lg-6 mx-auto">
                            <div className="career-form">
                                <h2 className="text-center">Apply for this Job</h2>
                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ setFieldValue }) => (
                                    <Form encType="multipart/form-data">
                                        <div className="formdemo">

                                            <div className="req-quote">
                                                <div className="req-field">
                                                    <Field name="name" type="text" className="form-control" placeholder="First Name" />
                                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                                </div>

                                                <div className="req-field">
                                                    <Field name="lname" type="text" className="form-control" placeholder="Last Name" />
                                                    <ErrorMessage name="lname" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="req-quote">
                                                <div className="req-field">
                                                    <Field name="email" type="email" className="form-control" placeholder="Email Address" />
                                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                                </div>

                                                <div className="req-field">
                                                    <Field name="dapartment" type="text" className="form-control" placeholder="Department" />
                                                    <ErrorMessage name="dapartment" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="req-quote">
                                                <div className="req-field w-100">
                                                    <Field name="phone" type="text" className="form-control" placeholder="Phone" />
                                                    <ErrorMessage name="phone" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="req-quote">
                                                <div className="req-field w-100">
                                                    <Field name="position" type="text" className="form-control" placeholder="Position applied for" />
                                                    <ErrorMessage name="position" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div id="msg-req" className="req-field">
                                                <Field name="message" type="text" className="form-control" placeholder="Message" component="textarea" />
                                            </div>

                                            <div className="req-quote">
                                                <div className="req-field w-100">
                                                    {/* <input  type="file" onChange={(event) => { setFieldValue("document", event.target.files[0].name); }} className="form-control" placeholder="Upload Your Resume" /> */}
                                                    <input type="file" onChange={HandleChange} className="form-control" placeholder="Upload Your Resume" />
                                                    <ErrorMessage name="document" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <button type="submit" className="btnContact w-100">Apply Now</button>
                                        </div>
                                    </Form>
                                    )}
                                </Formik>

                                <ToastContainer theme="colored" position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClickrtl={false} pauseOnFocusLoss draggable pauseOnHover />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CareerForm;