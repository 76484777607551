import { Link } from 'react-router-dom';

const FailureTwelve = () => {


    return (
        <>
        <div className="knowledgetitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>knowledge Base</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Pump Failure Modes - Imbalanced or damaged shaft</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pdf-sec mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-center mb-3">Pump Failure Modes - Imbalanced or damaged shaft</h4>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="pdf-knowledge">
                            <img src='images/knowledge/pump-failure-modes-imbalanced.webp' />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default FailureTwelve;