import { Link } from "react-router-dom";
import ContactFrom from "./ContactFrom";
//import { Helmet } from 'react-helmet';
const ContactUs = () => {

    return (
        <>
            {/* <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact Us" />
                <meta property="og:type" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta name="twitter:creator" content="" />}
                <meta name="twitter:card" content="" />
                <meta name="twitter:title" content="" />
                <meta name="twitter:description" content="" />
            </Helmet> */}
            <div className="contacttitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Contact Us</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="left-contact mt-5 text-center">
                                <p>Feel free to contact us with <br />any questions or suggestions</p>
                            </div>
                            <div className="address mt-5">
                                <ul>
                                    <li>
                                        <img src="images/email-contact.webp" />
                                        <h3>E-mail</h3>
                                        <a target='_blank' href="mailto:info@pumpacademy.in">info@pumpacademy.in</a>
                                    </li>

                                    <li>
                                        <img src="images/phone-contact.webp" />
                                        <h3>Phone</h3>
                                        <a target='_blank' href="tel:+918095588122">+91 80955 88122</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="whatsapp">
                                <h3><a target='_blank' href="https://api.whatsapp.com/send?phone=+918095588122&text=Hii"><img src="images/whatsapp.png" /> CALL/ WHATSAPP - +91-80955 88122</a></h3>
                            </div>
                        </div>

                        <ContactFrom />
                    </div>
                </div>
            </div>

            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.124114954552!2d77.64149821482192!3d12.963908940860982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150b5f8078bb%3A0xe527429d13b0c58!2sPump%20Academy!5e0!3m2!1sen!2sin!4v1676264872924!5m2!1sen!2sin" width="100%" height="450"></iframe>
            </div>
        </>
    )
}

export default ContactUs;