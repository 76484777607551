import { useEffect } from "react";
import { Link } from "react-router-dom";

const Product = () => {



    return (
        <>
            <div className="productsolution">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Products and solutions  </h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Products and Solutions</li>
                                </ul> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-products">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-5">
                            <div className="productimg">
                                <img src="images/products.webp" className="img-fluid" />
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-7">
                            <div className="boost-text">
                                <p>We offer five various models of end-to-end solutions to improve the capabilities of your assets, maintain effective operations, and reduce the life cycle cost (LCC) of your pumping stations.</p>
                                <div className="arrow-box">
                                    <div className="arrow"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="list-product">
                    <ul>
                        <li>
                            <img src="images/arrow-list.webp" />
                            <div className="clearfix"></div>
                            <p><span className="aie">i</span>Pumpnet Mini</p>
                        </li>

                        <li>
                            <img src="images/arrow-list.webp" />
                            <div className="clearfix"></div>
                            <p><span className="aie">i</span>Pumpnet Basic</p>
                        </li>

                        <li>
                            <img src="images/arrow-list.webp" />
                            <div className="clearfix"></div>
                            <p><span className="aie">i</span>Pumpnet Lite</p>
                        </li>

                        <li>
                            <img src="images/arrow-list.webp" />
                            <div className="clearfix"></div>
                            <p><span className="aie">i</span>Pumpnet Pro</p>
                        </li>

                        <li>
                            <img src="images/arrow-list.webp" />
                            <div className="clearfix"></div>
                            <p><span className="aie">i</span>Pumpnet Max</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="table-design">
                <div className="container">
                    <div className="card shadow">
                        <div className="table-responsive">
                            <table className="table align-items-center table-flush">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Sl.No.</th>
                                        <th scope="col">Parameters</th>
                                        <th scope="col" className="text-center"><span className="aie">i</span>Pumpnet Mini</th>
                                        <th scope="col" className="text-center"><span className="aie">i</span>Pumpnet Basic</th>
                                        <th scope="col" className="text-center"><span className="aie">i</span>Pumpnet Lite</th>
                                        <th scope="col" className="text-center"><span className="aie">i</span>Pumpnet Pro</th>
                                        <th scope="col" className="text-center"><span className="aie">i</span>Pumpnet Max</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="background-dark">
                                        <td colSpan="7">
                                            Operating Parameters
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            1
                                        </th>
                                        <td>
                                            <span>Number and hours, discharge  of each pump operating.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            2
                                        </th>
                                        <td>
                                            <span>Duration of the pumping station  operating with combination of  pumps including the combined  discharge of the pumping station.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            3
                                        </th>
                                        <td>
                                            <span>Static suction / delivery   head.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            4
                                        </th>
                                        <td>
                                            <span>Friction loss in suction /  delivery piping.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            5
                                        </th>
                                        <td>
                                            <span>Total head of pumping  station.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            6
                                        </th>
                                        <td>
                                            <span>Water levels of pump  suction intake well /  pumping station.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            7
                                        </th>
                                        <td>
                                            <span>Efficiency of each pump.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            8
                                        </th>
                                        <td>
                                            <span>Overall efficiency of the system.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            9
                                        </th>
                                        <td>
                                            <span>Online comparison of pump <br />parameters, actual vis-à-vis <br />committed performance like <br />discharge, head, power <br />consumption and efficiency.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            10
                                        </th>
                                        <td>
                                            <span>Online comparison of performance of pumps installed in pumping station.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            11
                                        </th>
                                        <td>
                                            <span>Online comparison of performance of pumping station with other pumping station.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            12
                                        </th>
                                        <td>
                                            <span>Pressure in Common manifold.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            13
                                        </th>
                                        <td>
                                            <span>Flow rate in the common manifold.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr className="background-dark">
                                        <td colSpan="7">
                                            Site Parameters
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            1
                                        </th>
                                        <td>
                                            <span>Online power availability,   input voltage, current,   frequency, power factor.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            2
                                        </th>
                                        <td>
                                            <span>Pump-wise power consumption.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            3
                                        </th>
                                        <td>
                                            <span>Power consumption of pumping  station.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            4
                                        </th>
                                        <td>
                                            <span>Bearing Temperature ℃   (max-min, average) Pump   & Motor (DE &NDE).</span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            5
                                        </th>
                                        <td>
                                            <span>Pump & Motor Vibration  mm/sec (max-min,  average).</span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            6
                                        </th>
                                        <td>
                                            <span>Noise level dBA  (max-min, average).</span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            7
                                        </th>
                                        <td>
                                            <span>Total hours of power  failure.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr className="background-dark">
                                        <td colSpan="7">
                                            Commercials
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            1
                                        </th>
                                        <td>
                                            <span>Cost of energy per Cum3  to take in account fixed  charges and energy charges.</span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            2
                                        </th>
                                        <td>
                                            <span>Calculation of penalty for  achieving below committed  efﬁciency resulting higher power
                                                consumption on month-to-month   basis, annual basis and  cumulative for the speciﬁed   period.</span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            3
                                        </th>
                                        <td>
                                            <span>Manpower, biometric attendance  – calculation of penalty for   deploying less manpower based   on contractual conditions,   brief bio-data of O & M staff.</span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            4
                                        </th>
                                        <td>
                                            <span>Calculation of penalty for not  achieving desired power factor  at pre decided rate.</span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr className="background-dark">
                                        <td colSpan="7">
                                            Basic reporting
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            1
                                        </th>
                                        <td>
                                            <span>Report of Abnormality in  machine behaviour.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            2
                                        </th>
                                        <td>
                                            <span>Exploring Dynamic saving opportunities  by regularly optimizing Pumping  Operations through machine  sequencing based on demand,  water level etc.                      </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            3
                                        </th>
                                        <td>
                                            <span>Maintain Automated Electronic  Logbooks.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            4
                                        </th>
                                        <td>
                                            <span>Monitoring, Analysis and submit  online the daily, weekly,  and monthly MIS in   prescribed format.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            5
                                        </th>
                                        <td>
                                            <span>Monthly Energy, Water and  Asset Performance Assessment,  Audit & Review and  submission of online monthly   reports.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr className="background-dark">
                                        <td colSpan="7">
                                            Additional reporting
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            1
                                        </th>
                                        <td>
                                            <span>Storage in local server of  web camera input for one  month.</span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            2
                                        </th>
                                        <td>
                                            <span>Real time consolidation of  performance at each pumping  station at AE Level / AEE Level  / EE Level / SE Level / CE Level
                                                / MD / Commission, Chairman,     CEO Level/ Secretary Level.
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr className="background-dark">
                                        <td colSpan="7">
                                            Repair and Maintenance
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            1
                                        </th>
                                        <td>
                                            <span>Details of alarms with time  tag; with escalation protocol  by email and if possible,  by SMS.
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            2
                                        </th>
                                        <td>
                                            <span>Time when the abnormally  attended and the person  concern.
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            3
                                        </th>
                                        <td>
                                            <span>Calculation of penalty for  delay in attending / rectifying  the defects / abnormality.
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            4
                                        </th>
                                        <td>
                                            <span>Spares and consumables  inventory control, date in  / out, cost, alert if   / inventory below minimum
                                                level.
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            5
                                        </th>
                                        <td>
                                            <span>Total no. of non-operative  pumping set with period  of non-operation.
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            6
                                        </th>
                                        <td>
                                            <span>Reason for non-operation  of pumping set.
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            7
                                        </th>
                                        <td>
                                            <span>Action taken for rectiﬁcation  to be ﬁlled in set  proforma.
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            8
                                        </th>
                                        <td>
                                            <span>Complete pump-wise  repair history of pumping  stations.
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr className="background-dark">
                                        <td colSpan="7">
                                            Additional features
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            1
                                        </th>
                                        <td>
                                            <span>Alerts, alarm on regular  maintenance and real  time scheduling
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            2
                                        </th>
                                        <td>
                                            <span>Real time GIS  (Geographic Information Systems)  based visualization in 2D/3D
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            3
                                        </th>
                                        <td>
                                            <span>Android and iOS(Apple)  platform independent mobile   application with profile and   level based access rights
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            4
                                        </th>
                                        <td>
                                            <span>Consolidation of Pumping  stations information at all  levels of Management
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            5
                                        </th>
                                        <td>
                                            <span>Integration, interoperability and  reconfiguration with other  devices, operating systems  and application in a new
                                                or retrofit environment  installation
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            6
                                        </th>
                                        <td>
                                            <span>Hybrid Cloud Platform -  Data Integration, storage  and Analytics
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            7
                                        </th>
                                        <td>
                                            <span>Advanced Metering Infrastructure  (AMI): Advantage of AMI:  Energy conservation and
                                                optimization by monitoring at  termination points.
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            8
                                        </th>
                                        <td>
                                            <span>Predictive and Preventive  maintenance with electric power  quality & vibration
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            9
                                        </th>
                                        <td>
                                            <span>Platform with Digital  twin
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            10
                                        </th>
                                        <td>
                                            <span>Deep Learning based  Artificial Intelligence enabled  condition monitoring
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            11
                                        </th>
                                        <td>
                                            <span>Water quality Analysis
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            12
                                        </th>
                                        <td>
                                            <span>Pumping station Inventory,  Management
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            13
                                        </th>
                                        <td>
                                            <span>Auto scheduling of pump  operation as per supply  and demand algorithm
                                            </span>
                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">

                                        </td>
                                        <td className="text-center">
                                            <i className="fal fa-check"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Product;