const IpumpnetExclusivity = () => {
    
    return (
        <>  <div id='exclusivity'></div>
            <div className="exclusivity">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <img src="images/first-kind.webp" className="img-fluid" />
                        </div>
                        <div className="col-md-5">
                            <div className="firstkind">
                                <h2>iPUMPNET Exclusivity</h2>
                                <h4>1st of its kind in India.</h4>
                                <p>The iPUMPNET is an advanced system that automatically connects the operating parameters of pumping stations with the dynamic performance of machines and systems. It includes features such as condition monitoring and fault diagnosis, allowing for a comprehensive view of the performance of each pumping set. This enables the system to provide valuable, interconnected information and insights that can lead to actionable improvements in the continuous operation of the pumping station.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="firstkind">
                                <p>iPUMPNET provides a centralized solution for monitoring pumping stations, allowing users to customize their monitoring and reporting systems to access real-time information and control over their pumping stations assets.</p>
                                <p>Specializes in providing advanced monitoring and optimization services for pumping stations. This includes real-time monitoring of pumping sets, as well as tools such as a pump LCC calculator and analysis to help reduce costs and improve efficiency. Additionally, your services allow for live data streaming to various devices, such as smartphones and desktop computers, over the web, providing clients with convenient access to important information</p>
                                <p>Through real-time monitoring and predictive and preventive maintenance techniques, iPUMPNET enables utilities to lower their operation and maintenance costs of pumping stations by taking timely actions to address deficiencies. With iPUMPNET's ability to detect pumping set failures or inefficient operation in real-time, it helps prevent pump failure, unscheduled downtime, and reduces emergency call-out expenses. This leads to near/virtually zero unscheduled downtime.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IpumpnetExclusivity;