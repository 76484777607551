import { Link, useLocation } from 'react-router-dom';
import ScrollToTop from '../components/home/BackTop';
import GetTouch from './GetTouch';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {

    const { pathname } = useLocation();
    var firstletterRemove = pathname;  //First Forward Slash Remove in URL
    var urlPure = firstletterRemove.substring(1);

    const API_PATH = 'https://www.pumpacademy.in/footermail.php';

    const [message, setMessage] = useState({
        sent: '',
        mess: ''
    });


    const initialValues = {
        email: '',
    };


    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string()
                .required('Email is required')
                .email('Email is invalid'),
        });
    }


    const handleSubmit = (data, { resetForm }) => {

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                'Content-Type': 'application/json'
            },
            data: data
        })
            .then(result => {
                setMessage({
                    sent: result.data.sent,
                    mess: result.data.message
                })
                toast.success("Thankyou for submitting enquiry!!");
                resetForm();
            })
            .catch(error => setMessage(error.sent));
    }

    return (
        <>
            <div className="main-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="getting text-center">
                                <h2>Getting started is easy</h2>
                                <p className="mt-4">Stop avoidable operating expenses, Start connecting your pumping station with iPUMPNET.</p>
                                <div className="get-demo mt-5">
                                  {urlPure === 'request-demo' ?   <Link to="/request-demo" onClick={()=>window.scrollTo(0, 0)}>Request Demo</Link> :   <Link to="/request-demo">Request Demo</Link>}
                                  {urlPure === 'contact-us' ?   <Link to="/contact-us" onClick={()=>window.scrollTo(0, 0)}>Contact Us</Link> :  <Link to="/contact-us">Contact Us</Link>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="bottom_footer" className="row">
                        <div className="col-md-12 col-lg-4">
                            <div className="footer-left">
                                
                                <div className="footer-mail mt-4">
                                    <ul>
                                        <li><i className="far fa-mobile"></i> <a target='_blank' href="tel:+918095588122">+91 80955 88122</a></li>
                                        <li><i className="fas fa-paper-plane"></i> <a target='_blank' href="mailto:info@pumpacademy.in">info@pumpacademy.in</a></li>
                                    </ul>
                                </div>

                                <div className="social_media">
                                    <ul>
                                        <li><a href="https://www.facebook.com/Pump-Academy-Private-Limited-105752739009810" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/pump-academy-private-limited-605652255/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="https://twitter.com/PumpAcademy" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://www.instagram.com/pumpacademy2021/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-8 col-md-12">
                            
                            <div className="clearfix"></div>
                            <div className="footer-email">
                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                    <Form>
                                        <Field name="email" type="email" className="email-control" placeholder="Your work email" autoComplete="off" />
                                        <button  onClick={handleSubmit}>See how it works</button>
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </Form>
                                </Formik>
                                <ToastContainer theme="colored" position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClickrtl={false} pauseOnFocusLoss draggable pauseOnHover />
                                <div className="clearfix"></div>
                                <p>For information about how iPUMPNET handles your personal data, please see our privacy policy.</p>
                            </div>
                        </div>
                    </div>

                    <div className="copyright-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="terma-condition">
                                        <ul>
                                            <li>
                                                <Link to="/privacy-policy">Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-conditions">Terms and Conditions</Link>
                                            </li>
                                            <li>
                                                <Link to="/disclaimer">Disclaimer</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="copy">
                                        <p>© 2022 Copyright Reserved to Pump Academy Private Limited.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="designed">
                                        <p>Website design and <br />Developed By</p>
                                        <img src="images/zenwebnet.webp" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
            <GetTouch />
        </>
    )
}

export default Footer;