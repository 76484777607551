import { Link } from 'react-router-dom';

const Media2023 = () => {

    return (
        <>
            <div className="mediatitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>News 2023</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Media</Link></li>
                                    <li><Link to="/Media">News</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container media-sec mt-20">                
                <div className="row no-gutters">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <ul className="list-group">                            

                            <a href="/download/news/2023-2024/World-Pumps-iPUMPNET-transform-water-utilities.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        iPUMPNET: Aims to Transform Water Utilities
                                    </h5>
                                    <small>
                                        World Pumps, December 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Everything-About-Water-November-2023-iPUMPNET-Unveiled-Minister-Karnataka.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        iPUMPNET: Unveiled by IT & BT Minister of Karnataka
                                    </h5>
                                    <small>
                                        Everything About Water, November 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Insights-Success-Nov-2023-Visionary-Leadership-Transforming-Futures.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Anil Sethi: Visionary Leadership Transforming Futures
                                    </h5>
                                    <small>
                                        Insights Success, November 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-October-2023-iPUMPNET-unvieled-IT-Minister.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        iPUMPNET: Unvieled by IT & BT Minister of Karnatka
                                    </h5>
                                    <small>
                                        Water Age, October 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Everything-About-Water-Oct-2023-iPUMPNET-Smart-Technology-Efficient-Pumping.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        iPUMPNET: Smart Technology for Efficient Pumping
                                    </h5>
                                    <small>
                                        Everything About Water, October 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-iPUMPNET-Sustainable-Solution-for-Optimizing-Pumping-Station-September-2023.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Interview: Chairman Pump Academy on Sustainable Solution for Optimizing Pumping Station
                                    </h5>
                                    <small>
                                        Water Age Magazine, September 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/iPUMPNET-sustainable-pumping-system-transformation.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        iPUMPNET for Sustainable Pumping System Transformation
                                    </h5>
                                    <small>
                                        B2B Purchase, September 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/profile-chairman-pump-academy-2023.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Profile: Chairman, Pump Academy
                                    </h5>
                                    <small>
                                        Insights Success, August 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/EAW-23rd-Anniversary-June-2023-Interview-Anil-Sethi.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Interview - Anil Sethi, Chairman
                                    </h5>
                                    <small>
                                        Everything About Water, 23rd Anniversary-June 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-Maximizing-Efficiency-Effective-Use-Pumping-Station.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Maximizing Efficiency: Effective Use of Pumping Station
                                    </h5>
                                    <small>
                                        Water Age Magazine, June 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-Embracing-Technology-Better-Future.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Embracing Technology for a Better Future
                                    </h5>
                                    <small>
                                        Water Age Magazine, June 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Equipment-Times-May-2023-vital-water-infrastructure.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Pumping System: Vital Water Infrastructure
                                    </h5>
                                    <small>
                                        Equipment Times, May 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Insights-Success-April-2023-PAPL-Profile.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Pump Academy Profile
                                    </h5>
                                    <small>
                                        Insights Success, April 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/EAW-April-2023-Pumping-System-Optimization-Reducing-Carbon-Footprint.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Pumping System Optimization: Reducing Carbon Footprint
                                    </h5>
                                    <small>
                                        Everything About Water, April 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/EAW-Earth-Day-Message.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Earth Day Message by Chairman, Pump Academy
                                    </h5>
                                    <small>
                                        Everything About Water, April 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/EAW-JSSATE-MoU.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Pump Academy Signed MoU with JSS Academy of Technical Education
                                    </h5>
                                    <small>
                                        Everything About Water, April 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-March-2023-PAPL-Earth-Day-Message.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Invest in Technology: Save the Planet
                                    </h5>
                                    <small>
                                        Water Age Magazine, March 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/EAW-World-Water-Day-PAPL.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Accelerating Change to Secure Water Sustainability
                                    </h5>
                                    <small>
                                        Everything About Water, March 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-March-2023-PAPL-JSSATE-MoU-Signinging-Coverage.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        PAPL-JSSATE Signing MoU
                                    </h5>
                                    <small>
                                        Water Age Magazine, March 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/TYCOON-Construction-Smart-Infrastructure-Advance-Pumping-System.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Smart Infrastructure: Advance Pumping System
                                    </h5>
                                    <small>
                                        TYCOON Construction & Architecture, Feb-March 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-Smart-Pumping-Secure-Water-Sustainability.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Smart Pumping to Secure Water Sustainability
                                    </h5>
                                    <small>
                                        Water Age Magazine, February 2023
                                    </small>
                                </li>
                            </a>

                            <a href="/download/news/2023-2024/Water-Age-Accelerating-change-secure-water-sustainability.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        World Water Day: Accelerating change to Secure Water Sustainability
                                    </h5>
                                    <small>
                                        Water Age Magazine, February 2023
                                    </small>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Media2023;