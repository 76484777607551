import AcademyWorks from '../home/AcademyWorks';

const TechnologyComponent = () => {

    return (
        <>
            <div className='academyhome'>
                <AcademyWorks />
            </div>
            <div id="highprecision" className="components">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Components of <span className="aie">i</span>PUMPNET</h2>
                            <p>High Precision IoT enabled Sensors</p>
                        </div>
                    </div>
                </div>

                <div id='precision' className="container mt-3 mb-5">
                    <div className="list-component">
                        <ul>
                            <li>
                                <img src="images/components/temperature-sensor.webp" className="img-fluid" />
                                <h4>Temperature <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/pressure-sensor.webp" className="img-fluid" />
                                <h4>Pressure <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/vibration-sensor.webp" className="img-fluid" />
                                <h4>Vibration <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/rpm-sensor.webp" className="img-fluid" />
                                <h4>Flow <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/level-sensor.webp" className="img-fluid" />
                                <h4>Level <br />Sensor</h4>
                            </li> 

                            <li>
                                <img src="images/components/rpm-sensors.webp" className="img-fluid" />
                                <h4>RPM <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/moisture-sensor.webp" className="img-fluid" />
                                <h4>Moisture <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/noise-sensor.webp" className="img-fluid" />
                                <h4>Noise <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/proximity-sensor.webp" className="img-fluid" />
                                <h4>Proximity <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/power-meter.webp" className="img-fluid" />
                                <h4>Power <br />Meter</h4>
                            </li>

                            <li>
                                <img src="images/components/thermal-imaging.webp" className="img-fluid" />
                                <h4>Thermal Imaging <br />Camera</h4>
                            </li>

                            <li>
                                <img src="images/components/conductivity-sensor.webp" className="img-fluid" />
                                <h4>Conductivity <br />Sensor</h4>
                            </li>

                            <li>
                                <img src="images/components/amplification-comp.webp"  className="img-fluid" />
                                <h4>Motion <br />Amplification Camera</h4>
                            </li>

                            <li>
                                <img src="images/components/biometric.webp" className="img-fluid" />
                                <h4>Biometric   <br />Attendance System</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>



            <div id='secured' className="iiot-manage">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-7">
                                <div className="iiot-based">
                                    <h2>High Precision Web of IoT enabled sensors</h2>
                                    <p>Specific precision sensors installed in each pump set for capturing various parameters
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-5">
                                <img src="images/high-presion-banner.webp" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>


            <div id='section-technology'>
                <div id='hardware' className="iiot-manage">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-5">
                                <img src="images/hardware-analysics.webp" className="img-fluid" />
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <div className="iiot-based">
                                    <h2>Hardware with Embedded Analytics</h2>
                                    <p>Auto Signal conditioner based 24-bit ADC ensuring almost zero signal delay
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='secured' className="iiot-manage">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-7">
                                <div className="iiot-based">
                                    <h2>Secured Communication</h2>
                                    <p>Bi-directional communication between cloud and multi-SIM based IoT gateway.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-5">
                                <img src="images/secured-communication.webp" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id='hybrid' className="iiot-manage">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-5">
                                <img src="images/hybrid-cloud.webp" className="img-fluid" />
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <div className="iiot-based">
                                    <h2>Hybrid Cloud and Mobile Application</h2>
                                    <p>Deep learning based Artificial Intelligence (AI) tools with digital twin and HMI in a cloud platform environment
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TechnologyComponent;