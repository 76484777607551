import { Link } from "react-router-dom";
import TechnologyComponent from "./TechnologyComponent";
import TechnologyPhara from "./TechnologyPhara";


const Technology=()=>{


    return(
        <>
          <div className="technologytitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Technology</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Technology</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TechnologyPhara /> 
            <TechnologyComponent />
        </>
    )
}

export default Technology;