
import PrivacyContent from "./PrivacyContent";
import { Link } from "react-router-dom";

const PrivacyBanner=()=>{

    return(
        <>
          <div className="privacytitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Privacy Policy</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Privacy Policy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyContent />
        </>
    )
}

export default PrivacyBanner;