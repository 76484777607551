import IpumpnetInfo from './IpumpnetInfo';
import { Link } from 'react-router-dom';
import IpumpnetSolution from './IpumpnetSolution';

const Ipumpnet=()=>{
    
    return(
        <>
        <div className="pagetitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>iPUMPNET</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>iPUMPNET</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <IpumpnetInfo />
            <IpumpnetSolution />
        </>
    )
}

export default Ipumpnet;