import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import TechnologyPhara from '../technology/TechnologyPhara';
import TechnologyComponent from '../technology/TechnologyComponent';

const IpumpnetSolution = () => {

    return (
        <>
            
            <TechnologyPhara />
            <TechnologyComponent />
        </>
    )
}

export default IpumpnetSolution;