import { HashLink } from 'react-router-hash-link';

const Monitored=()=>{




    return(
        <>
          <div className="monitored-ipumpnet">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="monitored-heading text-center">
                              <h4>Parameters</h4>
                              <h2>Monitored by iPUMPNET</h2>
                          </div>
                      </div>

                      <div className="col-md-12 mt-5">
                          <div className="monitored-points">
                              <ul>
                                  <li>
                                      <div className="wrap-list">
                                          <div className="image-left">
                                              <img src="images/performance.webp" />
                                          </div>
                                          <div className="content-right">
                                               <h4>Operating Performance</h4>
                                               <HashLink to='/parameters#operatingparameters'>Read now <i className="fas fa-angle-right"></i></HashLink>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="wrap-list">
                                          <div className="image-left">
                                              <img src="images/basic-reporting.webp" />
                                          </div>
                                          <div className="content-right">
                                               <h4>Basic Reporting</h4>
                                               <HashLink to='/parameters#basicreporting'>Read now <i className="fas fa-angle-right"></i></HashLink>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="wrap-list">
                                          <div className="image-left">
                                              <img src="images/site-parameters.webp" />
                                          </div>
                                          <div className="content-right">
                                               <h4>Site Parameters</h4>
                                               <HashLink to='/parameters#siteparameters'>Read now <i className="fas fa-angle-right"></i></HashLink>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="wrap-list">
                                          <div className="image-left">
                                              <img src="images/additional-reporting.webp" />
                                          </div>
                                          <div className="content-right">
                                               <h4>Additional Reporting</h4>
                                               <HashLink to='/parameters#additionalreporting'>Read now <i className="fas fa-angle-right"></i></HashLink>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="wrap-list">
                                          <div className="image-left">
                                              <img src="images/commercials.webp" />
                                          </div>
                                          <div className="content-right">
                                               <h4>Commercials</h4>
                                               <HashLink to='/parameters#commercials'>Read now <i className="fas fa-angle-right"></i></HashLink>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="wrap-list">
                                          <div className="image-left">
                                              <img src="images/repair-maintainance.webp" />
                                          </div>
                                          <div className="content-right">
                                               <h4>Repair and Maintenance</h4>
                                               <HashLink to='/parameters#repairmaintenance'>Read now <i className="fas fa-angle-right"></i></HashLink>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </>
    )
}

export default Monitored;