const ListPoint = () => {


    return (
        <>
            <div className="trademark">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img className="img-fluid shadow-lg rounded bg-body" src="images/trademark.webp" />
                            <img height="50%" width="50%" className="shadow-lg rounded bg-body mx-auto mt-5" src="images/BestBrands2024_Final.jpg" />
                        </div>
                        <div className="col-md-6">
                            <div className="points">
                                <ul>
                                    <li>iPUMPNET: The revolutionary technological solution for pumping station is unveiled by Mr. C. R. Paatil, Hon'ble Union Minister of Jal Shakti, Govt. of India during the India Water Week. </li>
                                    <li>Pump Academy Private Limited is conferred with the prestigious Economic Times Infra Focus Award 2024 as Best Brand in Water Technology.</li>
                                    <li>iPUMPNET: As an innovative product unveiled by Mr. Priyank Kharge, Hon’ble IT and BT Minister, Govt. of Karnataka during the the 26th edition of the Bengaluru Tech Summit.</li>
                                    <li>National Award for Excellence as “Top Most Water Management Leaders 2023” conferred to the Chairman of Pump Academy Private Limited.</li>
                                    <li>National Award for Excellence as “Top Most Water Technology Provider 2023” presented to Pump Academy Private Limited as a recognition for outstanding contributions in water management through optimization of pumping stations.</li>
                                    <li>Conferred with Asia’s Business & Leadership Awards 2023 in the category of Excellence in Business as India’s Best Pumping Optimization Technology of the Year.</li>
                                    <li>Received Certificate of Recognition as Most Promising Water Technology Provider for Pumping Station Optimization.</li>
                                    <li>Pump Academy Private Limited, which has been incorporated with registration number U25209KA2021PTC148378, is registered under Goods and Service Tax (GST) with registration number 29AAMCP0570F1ZA.</li>
                                    <li>The company holds a patent for "A deep learning based apparatus for control, monitoring and management of machines" with India Patent number 399853 and has applied for a patent for "Rank based condition monitoring of machines by an adaptive Meta deep learning method" under India Patent Application number 202341000533.</li>
                                    <li>It is also registered as a Startup with the Department of Promotion of Industry and Internal Trade (DPIIT) of the Government of India under registration number DIPP84920 and with the Karnataka Innovation and Technology Society (KITS) of the Government of Karnataka under registration number KITS/SK-REGN/2021-22/2003.</li>
                                    <li className="flex justify-between">Both <img className="pump-aca-logo" width="15%" src="images/logo-update.png" /> and <img width="15%" className="ipump-logo" src="images/ipumpnet.png" /> are registered trademarks in India</li>
                                    <li>The company is also registered under Micro, Small and Medium Enterprises in the Ministry of Government of India with registration number UDYAM-KR-03-0085677.</li>
                                    <li>Achieved NABL Certifications for our hardware units, which have successfully met the requirements of CISPR 11 Class A Ed. 6.2.2019-01 in the Industrial Grade Class A Category</li>
                                    <li>iPUMPNET enclosure has got the approval for the IP68 IEC605292013 Standard.</li>
                                    <li>Our company was chosen as one of the winner in the "Idea/Project under IDEA2PoC Grant Scheme (Elevate 2021)" of the Karnataka Start-up Policy, among around 1500 competitors.</li>
                                    <li>We have been nominated as one of five startups from NASSCOM Innovation Hubs/CoEs/TBIs for the Startup India Innovation Summit, organized by Startup India, DPIIT, and the Government of India.</li>
                                    <li>Industry-academic collaboration with JSS Academy of Technical Education (JSSATE), Bengaluru for knowledge sharing, focused research, and creating a vibrant ecosystem in engineering, technology, and management field. JSSATE is renowned for its advanced courses and attracts both Indian and international students</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="point-phara">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListPoint;