import { Link } from "react-router-dom";

const Projects = () => {


    return (
        <>
            <div className="projectstitle" id="projects">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <h1>Projects</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Projects</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-20">
                <div className="container mt-20">
                    <div className="row">
                        <div className="col-md-12">
                            <p>&nbsp;</p>
                            <b>Client:</b> Bangalore Water Supply and Sewerage Board, Bangalore, Karnataka<br></br><br></br>
                            <b>Project Brief:</b> The Bangalore Water Supply and Sewerage Board (BWSSB) is responsible for the management of water supply and wastewater disposal in the extensive Bruhat Bengaluru Mahanagar Palika Area, admeasuring around 800 square kilometers. BWSSB's primary goals is ensuring adequate water supply to the residents and commercial establishments while also maintaining sewerage network for the safe disposal of wastewater. They are also responsible for policies and implementing strategies to improve water supply and sewage management, and collecting water charges to maintain financial sustainability.<br></br><br></br>
                            Bengaluru, the capital city of Karnataka, is best known for IT innovation hubs globally and ranks as the third most populous metropolitan city in India. Its total population is estimated to be around 13.6 million (est. Sep 2023).<br /><br />
                            Pump Academy Private Limited is implementing the iPUMPNET project within the administration area of Bangalore Water Supply and Sewerage Board. This initiative covers nine pumping stations across Stage-1, Stage-2, and Stage-3 of TK Halli, Harohalli, and Tataguni, featuring motor ratings ranging from 1350 KW to 1500 KW. These water pumping stations have a combined water pumping capacity of 590 million litres per day (MLD).<br></br>
                            <p>&nbsp;</p>                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-8">
                        <div id="tblProjects" className="table-responsive mt-20">
                                <table className="table">
                                    <tr>
                                        <th>Total number of Pumps</th>
                                        <th>Total number of pumping stations</th>
                                        <th>Mator Capacity in KW</th>
                                        <th>Total Motor Capacity in KW</th>
                                        <th>Input Voltage (V)</th>
                                        <th>Total water Discharge (MLD)</th>
                                        <th>Total Head (Mtr)</th>
                                    </tr>
                                    <tr>
                                        <td className="text-center">54</td>
                                        <td className="text-center">9</td>
                                        <td className="text-center">1350 KW to 1500 KW</td>
                                        <td className="text-center">76,500 KW</td>
                                        <td className="text-center">6,600</td>
                                        <td className="text-center">590</td>
                                        <td className="text-center">320</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects;