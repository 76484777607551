import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const GuidelinesFor = () => {

 


    return (
        <>

            <div className="knowledgetitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-3">
                            <div className="page-tit">
                                <h1>knowledge Base</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-9">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Chapter - Guidelines for proper sump / reservoir design & suction pipe arrangement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pdf-sec mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-center mb-3">Chapter - Guidelines for proper sump / reservoir design & <br />suction pipe arrangement</h4>
                            <div className="pdf-knowledge">
                                <img src='images/knowledge/chapter-guidelines-for-proper-sump-design-suction-pipe-arrangement.webp' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default GuidelinesFor;