import DisclaimerContent from "./disclaimerContent";
import { Link } from "react-router-dom";

const DisclaimerBanner = () => {


    return (
        <>
            <div className="termstitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Disclaimer</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Disclaimer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DisclaimerContent />
        </>
    )
}

export default DisclaimerBanner;