import { Link } from "react-router-dom";
import Commercials from "./Commercials";
import OperatingParemeters from "./OperatingParameters";
import SiteParamaters from "./SiteParameters";
import BasicReporting from "./BasicReporting";
import AdditionalReporting from "./AdditionalReporting";
import Repair from "./Repair";

const BannerParameters=()=>{



    return(
        <>
        <div className="parametertitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-tit">
                                <h1>Parameters</h1>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Parameters</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OperatingParemeters />
        </>
    )
}

export default BannerParameters;