import { Link } from 'react-router-dom';
import Team from './Team';

const TeamBanner = () => {

    

    return (
        <>
            <div className="teamtitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-tit">
                                <h1>Our Team</h1>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Team</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Team />
        </>
    )
}

export default TeamBanner;