import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TopBar = () => {

    const [open, setOpen] = useState();
    const [chapter, setChapter] = useState(false);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        if (!chapter) {
            setOpen(false);
        }
    }, [chapter])

    useEffect(() => {

        const concernedElement = document.querySelector(".knowledge");

        document.addEventListener("click", (event) => {
            if (concernedElement.contains(event.target)) {

            } else {
                setOpen(false)
            }
        });

    }, [])

    useEffect(() => {

        const concernedElement2 = document.querySelector(".media");

        document.addEventListener("click", (event) => {
            if (concernedElement2.contains(event.target)) {
            } else {
                setToggle(false)
            }
        });

    }, [])

    return (
        <>
            <div className="top-bar">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-4">
                            <div className="top-mail">
                                <ul>
                                    <li><i className="far fa-mobile"></i> <a target='_blank' href="tel:+918867213434">+91 88672 13434</a></li>
                                    <li><i className="fas fa-paper-plane"></i> <a target='_blank' href="mailto:info@pumpacademy.in">info@pumpacademy.in</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8 text-right">
                            <div className="top-right text-right">
                                <ul>
                                    <li><Link to="/about">About us</Link></li>
                                    <li className="knowledge"><a onClick={() => setOpen(!open)}>Knowledge base</a>
                                        {open && <ul className="dropdown-menu">
                                            {/* <li onClick={() => setOpen(!open)}><Link className="dropdown-item" to="/pumpacademy-ipumpnet">Pump Academy - iPUMPNET</Link></li> */}
                                            <li><Link className="dropdown-item" to="/indicative-major-pump" onClick={() => setOpen(!open)}>Chapter - Indicative major pump failure modes</Link>
                                                <div className="right-span" onClick={() => setChapter(!chapter)}>{chapter ? <i className="fal fa-minus"></i> : <i className="fal fa-plus"></i>}</div>
                                                {chapter &&
                                                    <ul className="chapter-drop">
                                                        <li><Link to='/pump-failure-modes-excessive-power-consumption' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Excessive Power Consumption</Link></li>
                                                        <li><Link to='/pump-failure-modes-cavitation' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Cavitation</Link></li>
                                                        <li><Link to='/pump-failure-modes-bearing-failure' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Bearing Failure</Link></li>
                                                        <li><Link to='/pump-failure-modes-excessive-vibration' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Excessive Vibration</Link></li>
                                                        <li><Link to='/pump-failure-modes-less-flow-yield-than-expected' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Less Flow Yield than Expected</Link></li>
                                                        <li><Link to='/pump-failure-modes-pump-operating-without-discharge' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Pump Operating without Discharge</Link></li>
                                                        <li><Link to='/pump-failure-modes-leakage' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Leakage</Link></li>
                                                        <li><Link to='/pump-failure-modes-seal-failure' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Seal Failure</Link></li>
                                                        <li><Link to='/pump-failure-modes-pumps-overheats' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Pumps Overheats</Link></li>
                                                        <li><Link to='/pump-failure-modes-corrosion' onClick={() => setChapter(!chapter)}>Pump Failure Modes  - Corrosion</Link></li>
                                                        <li><Link to='/pump-failure-modes-lubrication-failure' onClick={() => setChapter(!chapter)}>Pump Failure Modes  - Lubrication Failure</Link></li>
                                                        <li><Link to='/pump-failure-modes-imbalanced-or-damaged-shaft' onClick={() => setChapter(!chapter)}>Pump Failure Modes - Imbalanced or damaged shaft</Link></li>
                                                    </ul>
                                                }
                                            </li>
                                            <li onClick={() => setOpen(!open)}><Link className="dropdown-item" to="/sub-optimal">Chapter - sub-optimal pump performance</Link></li>
                                            <li onClick={() => setOpen(!open)}><Link className="dropdown-item" to="/various-head-terms">Chapter - Various Head Terms</Link></li>
                                            <li onClick={() => setOpen(!open)}><Link className="dropdown-item" to="/guidelines-for-proper-sump">Guidelines for proper sump / reservoir design & <br />suction pipe arrangement</Link></li>
                                        </ul>
                                        }
                                    </li>
                                    <li><Link to="/credentials">Credentials</Link></li>
                                    <li><Link to="/team">Team</Link></li>
                                    <li className="media"><a onClick={() => setToggle(!toggle)} >Media</a>
                                        {toggle && <ul className="dropdown-menu">
                                            <li><Link className="dropdown-item" to="/Media">News</Link></li>
                                            <li><Link className="dropdown-item" to="/Press">Press Release</Link></li>
                                            {/* <li><Link className="dropdown-item" to="/Blog">Blog</Link></li> */}
                                        </ul>
                                        }
                                    </li>
                                    <li><Link to="/downloads">Download</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/contact-us">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar;