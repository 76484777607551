import { useRef } from "react";
import { Link } from "react-router-dom";

const OperatingParemeters = () => {


    const Operatingper = useRef(null);
    const Site  = useRef(null);
    const Basic  = useRef(null);
    const Additional  = useRef(null);
    const Commercial  = useRef(null);
    const Repain  = useRef(null);

   function Operating(){
    Operatingper.current.scrollIntoView();
   }

   function SiteParam(){
    Site.current.scrollIntoView();
   }
  
   function BasicRep(){
    Basic.current.scrollIntoView();
   } 

   function AdditionalRepo(){
    Additional.current.scrollIntoView();
   }

   function Commercials(){
    Commercial.current.scrollIntoView();
   }
   function RepainMaintainance(){
    Repain.current.scrollIntoView();
   }

    return (
        <>


            <div className="monitored-ipumpnets">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="monitored-heading text-center">
                                <h4>Parameters Monitored by iPUMPNET</h4>
                            </div>
                        </div>

                        <div className="col-md-12 mt-4">
                            <div className="monitored-points">
                                <ul>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/performance.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Operating Parameters</h4>
                                                <Link to='/parameters' onClick={Operating}>Read now <i className="fas fa-angle-right"></i></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/site-parameters.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Site Parameters</h4>
                                                <Link to='/parameters' onClick={SiteParam}>Read now <i className="fas fa-angle-right"></i></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/commercials.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Commercials</h4>
                                                <Link to='/parameters' onClick={Commercials}>Read now <i className="fas fa-angle-right"></i></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/basic-reporting.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Basic Reporting</h4>
                                                <Link to='/parameters' onClick={BasicRep}>Read now <i className="fas fa-angle-right"></i></Link>
                                            </div>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/additional-reporting.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Additional Reporting</h4>
                                                <Link to='/parameters' onClick={AdditionalRepo}>Read now <i className="fas fa-angle-right"></i></Link>
                                            </div>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/repair-maintainance.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Repair and Maintenance</h4>
                                                <Link to='/parameters' onClick={RepainMaintainance}>Read now <i className="fas fa-angle-right"></i></Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div ref={Operatingper} id="operatingparameters" className="monitored-ipumpnet">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="monitored-heading text-center">
                                <h4>Operating Parameters</h4>
                            </div>
                        </div>

                        <div className="col-md-12 mt-1">
                            <div className="operating-parameters">
                                <ul>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/1.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Number of Pumps operating</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/hours-of-pump-run.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Hours of run Pump wise.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/3.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Hours of run of the pumping station with combination of pumps.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/discharge-pump-wise.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Discharge Pump wise.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/total-discharge.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Total discharge of Pumping Station.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/discharge-head.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Static Suction Head.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/friction-loss-suction.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Friction Loss in Suction piping.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/static-head.jpg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Static Delivery head.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/friction-loss.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Friction Loss in Delivery piping system.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/discharge-head.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Total Head of Pumping system.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/11.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Water levels of pump suction Intake well/ Pumping Station.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/12.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Pressure in Common manifold.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/flow-rate-common.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Flow rate in the common manifold.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/14.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Efficiency of Each pump.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/15.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Combined Efficiency of the Pumping Station.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/16.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Overall efficiency of the System.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/discharge-head.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Online comparison of the pump parameters: actual vis a vis committed performance of discharge, head, power consumption and efficiency</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div ref={Site} id="siteparameters" className="monitored-ipumpnet">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="monitored-heading text-center">
                                <h4>Site Parameters</h4>
                            </div>
                        </div>

                        <div className="col-md-12 mt-1">
                            <div className="operating-parameters">
                                <ul>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/1.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Online power availability/ power failures.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/2.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Input voltage.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/3.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Current, frequency.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/4.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Power factor.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/5.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Power consumption Pump wise.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/6.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Power consumption of Pumping Station.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/7.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Bearing Temperature ℃ (max-min, average) Pump (DE & NDE).</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/8.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Bearing Temperature ℃ (max-min, average) Motor (DE & NDE).</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/9.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Pump Vibration mm/sec (max-min, average).</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/operating/motor-vibration.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Motor Vibration mm/sec (max-min, average).</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/siteparameters/11.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Total hours of power failure.</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div ref={Commercial} id="commercials" className="monitored-ipumpnet">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="monitored-heading text-center">
                                <h4>Commercials</h4>
                            </div>
                        </div>

                        <div className="col-md-12 mt-1">
                            <div className="operating-parameters">
                                <ul>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/commercial/1.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Cost of energy per cubic metre taking into account fixed charges and energy charges. The reports can be generated on daily, weekly, monthly & yearly basis and in comparison to various other pumping stations.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/commercial/2.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Penalty for achieving below committed efficiency resulting higher power consumption.  On month-to-month basis, annual basis and cumulative for the specified period.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/commercial/3.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Penalty for not achieving desired power factor at pre decided rate.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/commercial/4.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Calculating the liquidated damages at pre-defined formula for various defaults by the O & M contractor.</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={Basic} id="basicreporting" className="monitored-ipumpnet">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="monitored-heading text-center">
                                <h4>Basic Reporting</h4>
                            </div>
                        </div>

                        <div className="col-md-12 mt-1">
                            <div className="operating-parameters">
                                <ul>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/basic-reporting/1.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Report of Abnormality in machine behaviour.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/basic-reporting/2.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Exploring Dynamic saving opportunities by regularly optimizing Pumping Operations through machine sequencing based on demand, water level etc.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/basic-reporting/3.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Maintain Automated Electronic Logbooks.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/basic-reporting/4.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Monitoring, Analysis and submit online the daily, weekly, and monthly MIS in prescribed format.</h4>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/basic-reporting/5.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Monthly Energy, Water and Asset Performance Assessment, Audit & Review and submission of online monthly reports.</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={Additional} id="additionalreporting" className="monitored-ipumpnet">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="monitored-heading text-center">
                                <h4>Additional Reporting</h4>
                            </div>
                        </div>

                        <div className="col-md-12 mt-1">
                            <div className="operating-parameters">
                                <ul>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/additional-reporting/1.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Storage in local server of web camera input for one month.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/additional-reporting/2.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Real time Consolidation of performance of pumping station.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/additional-reporting/3.webp" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Online comparison of the performance of pumping stations at various locations, including discharge, head, power consumption, and efficiency.</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={Repain} id="repairmaintenance" className="monitored-ipumpnet pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="monitored-heading text-center">
                                <h4>Repair & Maintenance</h4>
                            </div>
                        </div>

                        <div className="col-md-12 mt-1">
                            <div className="operating-parameters">
                                <ul>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/repair-maintanance/1.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Details of alarms with time tag; with escalation protocol by email and if possible, by SMS.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/repair-maintanance/2.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Capture details of timings, when abnormality was attended and by whom.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/repair-maintanance/3.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Calculate the Penalty for delay in attending /rectifying the defects/abnormality.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/repair-maintanance/4.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Record Spares and consumables inventory, date in /out, cost, alert if inventory below minimum level.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/repair-maintanance/5.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Record Total no of non-operative Pumping set with period of non-operation.</h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="wrap-list">
                                            <div className="image-left">
                                                <img src="images/parameters/repair-maintanance/6.svg" />
                                            </div>
                                            <div className="content-right">
                                                <h4>Record Reason for non-operation of Pumping set.</h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OperatingParemeters;