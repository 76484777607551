import React from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactFrom = () => { 

    const API_PATH = 'https://www.pumpacademy.in/contact.php';

    const [message, setMessage] = useState({
        sent:'',
        mess:''
      });
    

    const initialValues = {
        name: '', 
        lname: '',
        email: '',
        phone: '',
        message: '',
    };


    const validationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required('Name is required')
                .min(4, 'Name must be at least 3 characters'),
            email: Yup.string()
                .required('Email is required')
                .email('Email is invalid'),
            phone: Yup.number()
                .required('A phone number is required')
                .min(10),
        });
    }


    const handleSubmit = (data, { resetForm }) => {

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                'Content-Type': 'application/json'
            },
            data: data
        })
            .then(result => {
                setMessage({
                    sent: result.data.sent,
                    mess: result.data.message
                })
            toast.success("Thankyou for submitting enquiry!!");
            resetForm();
            })
            .catch(error => setMessage(error.sent));
    }

    return (
        <>
            <div className="col-md-12 col-lg-6 p-0">
                <div className="contact-form">
                    <h4>Fill out this short form and a member of our team will get back to you within 24 hours</h4>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>  
                  <Form>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>First Name</label>
                            <Field name="name" type="text" className="form-control" />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div id="lastname" className="col-md-6">
                            <label>Last Name</label>
                            <Field name="lname" type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label>Email</label>
                            <Field name="email" type="email" className="form-control" />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label>Phone</label>
                            <Field name="phone" type="text" className="form-control" />
                            <ErrorMessage name="phone" component="div" className="text-danger" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label>Message</label>
                            <Field name="message" type="text" className="form-control" component="textarea" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12 text-center">
                            <input type="submit" className="btnContact" id="send" name="submitcust" onClick={handleSubmit} value="Send REQUEST" />
                        </div>
                    </div>
                 </Form>  
                </Formik>
                <ToastContainer theme="colored" position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClickrtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </div>
            </div>
        </>
    )
}

export default ContactFrom;