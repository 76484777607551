const PrivacyPolicy = () => {


    return (
        <>
            <div className="terms-conditions mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-content">
                                <h2>PRIVACY POLICY</h2>
                                <p>Pump Academy Private Limited, a private limited company with its registered office at D Blk-1006, Ittina Akkala, Hoodi Circle, Rajapalya, Bangalore – 560048, Karnataka, India <b>(“Company”, “We”, “Us”, “Our”</b> and terms of similar meaning) is committed to protecting your privacy. This Privacy Policy shall apply to the Visitors (as defined in the Terms of Use). For the purposes of this Privacy Policy, the Visitors, (as defined in the Terms of Use) shall together hereinafter be referred to as <b>“You”</b>, <b>“Your”</b> or <b>“Yourself”</b>.</p>
                                <p>This Privacy Policy shall be read in conjunction and together with the Terms of Use and forms an integral part of the agreement between You and the Company.</p>
                                <p>The Company has adopted this Privacy Policy to set out the manner in which personal data and other information is collected, received, stored, processed, disclosed, transferred, dealt with and handled by the Company in relation to Your use of Our Platform(defined below) through the website www.pumpacademy.in (<b>“Website”</b>). The Platform may contain links to other websites. This Privacy Policy does not apply to information that You provide to, or that is collected by, any third-party through the Platform, and any third-party websites that You access through the Platform which leads to a third-party platform or website. Company shall not be liable for and has no control over the services provided, practices, and content of any such third-party website.</p>
                                <p>Please read the Privacy Policy carefully before using or registering on the Platform or accessing any material, information or availing any details of the product provided by the Company through the Platform (<b>“Platform”</b>). By clicking on the “<b>I accept</b>” button on the landing page, You accept this Privacy Policy and agree to be legally bound by the same. If You do not agree to the Privacy Policy, please do not use or access the Platform.</p>
                                <p>This Privacy Policy is incorporated into and subject to Our Website Terms and Conditions (“Terms of Use”) and shall be read harmoniously and in conjunction with the Terms of Use. In the event of any conflict between the Terms of Use and this Privacy Policy, the provisions of the Terms of Use shall supersede the Privacy Policy. All capitalised terms not defined under this Privacy Policy shall have the meaning ascribed to it under the applicable Terms of Use.</p>
                                <p>Please read this Privacy Policy in conjunction with Our other terms and conditions and policies available at Our Platform. When You access or use the Platform, You agree to Our Privacy Policy, and You consent to Our collection, storage, use, and disclosure of Your Personal Data including cache information for the purpose of making the Platform and/or our service available to You, in accordance with the Privacy Policy. We collect, store, use and disclose Personal Information in accordance with all applicable laws in India, relating to the protection of Personal Information. While you may be able to browse the Platform from countries outside of India, however, please note we do not offer any Product/Service under this Platform outside India.</p>
                                <p>The Privacy Policy constitutes an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                                <p>If You do not accept and agree with Our Privacy Policy, then You must cease to access or use of Our Platform.</p>
                                <p>We commit to protecting your privacy and hence our Personal Data (defined herein below) handling practices are continually reviewed to ensure compliance with the applicable Privacy laws and regulations.</p>

                                <h3 className="mt-5 d-block">I.	INTERPRETATION AND DEFINITIONS</h3>
                                <h4>1.	Interpretation</h4>
                                <p>The words of which the initial letter is capitalized have meanings defined under the following Clause 2. The following definitions shall have the same meaning, regardless of whether they appear in singular or in plural form.</p>

                                <h4>2.	Definitions</h4>
                                <p>For the purposes of this Privacy Policy:</p>
                                <ul>
                                    <li>"<b>Advertisement</b>" refers to details of the products displayed on the Platform of the Company stating the details of the product.</li>
                                    <li>“<b>Cache</b>” means a high-speed data storage layer which stores a subset of data, typically transient in nature.</li>
                                    <li>"<b>Company</b>" refers toPump Academy Private Limited, with its registered office at D Blk-1006, Ittina Akkala, Hoodi Circle, Rajapalya, Bangalore – 560048, Karnataka, India.</li>
                                    <li>"<b>Cookies</b>" are small files that are placed on Your computer, mobile device or any other device by Our Website, containing the details of Your browsing history on Our Platform.</li>
                                    <li>"<b>Country</b>" refers to the state of Karnataka, India.</li>
                                    <li>"<b>Device</b>" means any IT device that can access the Platform such as a computer, a cell phone, hand held device and/ or a digital tablet.</li>
                                    <li>"<b>Non-Personal Data</b>" shall mean data that is not Personal Data.</li>
                                    <li>"<b>Personal Information/Data</b>" means any information that relates to a natural person, which, either directly or indirectly or in combination with other information available with Us, is capable of identifying an individual.</li>
                                    <li>"<b>Platform</b>" refers to the details of the products that We provide as a Company that are provided on the Platform.</li>
                                    <li>"<b>Sensitive Personal Data or Information</b>” means sensitive information of any individual relating to password; physical, physiological and mental health condition; sexual orientation; medical records; biometric information; any detail relating to the above as provided to or received by Us for processing or storage. However, any data/ information relating to an individual that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other applicable law shall not qualify as Sensitive Personal Data.</li>
                                    <li>"<b>Service Provider</b>” means any natural or legal person who processes the data on behalf of the Company. It refers to the third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analysing how the Service is used.</li>
                                    <li>"<b>Usage Data</b>” refers to data collected automatically, either generated by the use of Our Platform or from the Platform infrastructure available on the Platform(for example, the duration of a page visit).</li>
                                    <li>"<b>Visitor/You</b>” means the individual accessing or using the Platform, or the company, or other legal entity on behalf of which such individual is accessing or using the Platform, as applicable.</li>
                                </ul>

                                <h4>II.	THIS PRIVACY POLICY HAS BEEN DESIGNED AND DEVELOPED TO HELP YOU UNDERSTAND THE FOLLOWING:</h4>
                                <ul>
                                    <li>The type of Personal Information that the Company, though its Platform collect from its Visitor(s);</li>
                                    <li>The purpose of collection, means and modes of usage of such Personal Information by the Company;</li>
                                    <li>How and to whom the Company will disclose such information;</li>
                                    <li>How the Company will protect the Personal Information that is collected from theVisitor; and</li>
                                    <li>How Visitors may access and/ or modify their Personal Information.</li>
                                </ul>

                                <h4 className="d-block mt-5">II.	COLLECTING AND USING YOUR PERSONAL DATA:</h4>
                                <h5 className="d-block mt-2"><strong>(A)</strong> WHAT WE COLLECT</h5>
                                <h5 className="d-block mt-5">Types of Data Collected</h5>
                                <p>We collect both Your Usage Date and Personal Information herein collectively referred to (the Personal Data). We generally do not seek Sensitive Personal Data from Our Visitor, and we ask that You do not provide such data.  If You voluntarily share with Us or post/upload  any Sensitive Personal Data to this Platform for any other reason, You consent that We may use such data in accordance with applicable law and this Privacy Policy. While using Our Platform, We may ask You to provide Us with Your Personal Data that may include but is not limited to:</p>
                                <ul>
                                    <li>Email address;</li>
                                    <li>First name and last name;</li>
                                    <li>Phone number;</li>
                                    <li>Payment details;</li>
                                    <li>Address, State, Province, ZIP/Postal code, City;</li>
                                    <li>Usage Data;</li>
                                    <li>Any detail relating to the above clauses as provided to Us for providing the Service; and;</li>
                                    <li>Any of the information received under above clauses by Us for processing, storing the Personal Data.</li>
                                    <li>Technical details such as device information, location and network carrier when you use our Application available on mobile and other hand held devices;</li>
                                    <li>Communications details such as the metadata and other Personal Sensitive Data we get from communications done through e-mails, SMS, instant messages and calls.</li>
                                    <li>Email address;</li>
                                </ul>

                                <p>In addition to the above, We may obtain and use the following Personal Data about You and anyone You chose to add to Our Platform. In addition to the above, We also collect Usage Data generated from the circumstances below:</p>

                                <ul>
                                    <li><b>Correspondence</b>: Any correspondence we have with Visitors or should the Visitors contact Us.</li>
                                    <li><b>Your preferences</b>: Your preferences and settings such as time zone and language.</li>
                                    <li><b>Your searches and other activities</b>: The search terms you have looked up and results you selected.</li>
                                    <li><b>Your browsing information</b>: How long you used our Platform, and which features you used; the ads you clicked on.</li>
                                    <li><b>Your Search</b>: Details of Your visits to Platform, which includes without limitation location and traffic data, weblogs, resources You access and other communication data.</li>
                                    <li><b>Your Content</b>: Information you provide through our Platform, including your email address, phone number, name, and details of the product search.</li>
                                    <li><b>Your Public Posts</b>: You also may provide information (such as ratings, reviews, tips, photos, comments, likes, bookmarks, friends, lists, etc.) to be published or displayed on publicly accessible areas of Our Platform or transmitted to other third-parties websites.</li>
                                    <li><b>Location</b>: When You use one of our location-enabled services (for example, when you access Platform from a mobile device), we may collect and process information about Your mobile device's GPS location (including the latitude, longitude or altitude of your mobile device) and the time the location information is recorded to customize the Platform with location-based information and features.</li>
                                    <li><b>Usage Data</b>: To elaborate, all kinds of Usage Data are collected automatically when using the Platform. Usage Data may include information such as Visitor device’s internet protocol address (e.g., IP address), browser type, browser version, the pages of Our Platform thatVisitor visit, the time and date of Visitor visit, the time spent on those pages, unique device identifiers and other cyber diagnostic data. When Visitor access the Platform through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device, the type of mobile internet browser that's being used, unique device identifiers and other cyber diagnostic data.</li>
                                    <li><b>Device</b>: When and if You download and/or use Our Platform through Your device, We may receive information about Your location and Your device, including a unique identifier number for Your device, device model, operating systems, versions, software, file names, advertisement identifiers and mobile network information. We may use this information to provide You with location-based Platform including but not limited to search results and other personalized content. When You use the Platform through the telecommunication device, we collect Your location data. If You permit the Platform to access Your location through the permission system used by Your mobile operating system, We may also collect the precise location of Your device when the Website is running in the foreground or background. We may also derive Your approximate location from Your IP address.</li>
                                    <li>It may be noted thatWe shall not/will not be responsible for the authenticity of the Personal Data provided by the Visitor.</li>
                                </ul>

                                <h4 className="mt-4 d-block"><strong>(B)</strong> HOW WE COLLECT</h4>
                                <h5 className="mt-4">1.	How do We collect Personal Data</h5>
                                <ul>
                                    <li>directly from You when You provide it to Us voluntarily on the website details dial box; and/or</li>
                                    <li>automatically as you navigate through our Platform (information collected automatically may include usage details, IP addresses and information collected through cookies, web beacons and other tracking technologies).</li>
                                </ul>

                                <h5>2. How We collect Non-Personal Data</h5>
                                <p>We gather statistical and other analytical information collected on an aggregate basis from all visitors on the Platform. This Non-Personal Data comprises information that cannot be used to identify or contact Our Visitors, such as demographic information regarding, for example, clipped or anonymized Visitor IP addresses, browser types and other anonymous statistical data involving the use of Platform to understand geography of Our Visitors and help Us better organize the Platform/ and the Platform can provide better product solutions to Our Visitors.</p>

                                <h5>3.	How we collect Automatic Data</h5>
                                <p>We gather Usage Data such as date, time, browser type, navigation history of all who visit the Platform. The information gathered is used for Our internal security audit log, for analysing trends, for system administration purposes and, also, to gather Visitor behaviour and usage patterns on the Platform. We may combine information supplied by You during Your visit on the Platform with Usage Data to learn more about Visitors and their preferences about the content available on the Platform.We may use this information to tailor Our collaboration with specific interests of Visitors, or to contact Visitors about offers from Our business associates and Third Parties which may be of interest to them.</p>

                                <h5>4.	Purpose limitation</h5>
                                <p>We collect Personal Data only for specified, explicit and legitimate purposes. We do not use Personal Data for incompatible purposes from that disclosed when it was first obtained unless we have informed the Visitors of the new purposes, and they have consented where necessary.</p>

                                <h4 className="mt-4 d-block"><strong>(C)</strong> HOW WE COLLECT</h4>

                                <h5 className="mt-4 d-block">1.	The Company may use Personal Data for the following purposes:</h5>
                                <ul>
                                    <li><strong>To provide and maintain Our Platform,</strong> including to monitor the usage of our Platform.</li>
                                    <li><strong>To manage Visitors accessibility:</strong> to manage and provide ease of access to the Visitors. The Personal Data You provide can give You access to details of different products listed on Our Platform that are available to Visitors.</li>
                                    <li><strong>To contact Visitors:</strong> To contact Our Visitors by email, telephone calls, SMS, or other equivalent forms of electronic communication, to provide the necessary details of the products requested by the Visitor.</li>
                                    <li><strong>To provide Visitors:</strong> with news, special offers and general information about other goods, services and events which We offer that are similar to those that Visitors have already requested or enquired about unless Visitors have opted not to receive such information.</li>
                                    <li><strong>To provide Customer Support:</strong> To provide Visitors with customer support. </li>
                                    <li><strong>Compliance with law:</strong> To comply with applicable Laws, court orders, government and law enforcement agencies’ requests.</li>
                                    <li><strong>For other purposes:</strong> We may use  information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of Our promotional campaigns and to evaluate and improve Our Website, products, services, marketing and Your experience. We may also use this information to do internal research on our demographics, interests, and behaviour to better understand, protect and serve You better. This information is compiled and analysed on an aggregated basis and in a manner that does not personally identify You. We indicate fields that are mandatorily required to be filled and fields that are optional. You may decide whether or not to provide such information to Us.</li>
                                    <li><strong>Managing and Collating Feedback/Reviews:</strong> We may collect information for collating Your feedback, providing offers, doing surveys and conducting research on Our user base for usage and improvement of Platform.</li>
                                </ul>

                                <h5 className="mt-4 d-block">2.	Why We Collect Cookies:</h5>
                                <p>Cookies are small text files that are placed on your computer by websites that you visit. Similarly, there are other types of trackers used. Likewise, mobile applications use requisite permissions and Software Developments Kits (SDKs). These are used to make websites & apps work, or work more efficiently, as well as to provide information to the owners of the website/ app.</p>
                                <p>We, and third parties with whom we partner, may use cookies, pixel tags, web beacons, mobile device IDs, "flash cookies" and similar files or technologies to improve, collect and store information in respect to Your use of the Platform. The technologies We use may include:</p>
                                <ul>
                                    <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of Our Platform. Unless You have adjusted Your browser setting so that it will refuse Cookies, Our Platform may use Cookies.</li>
                                    <li><strong>Flash Cookies.</strong> Certain features of Our Platform may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on Our Platform. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how Visitors can delete Flash Cookies, please read “Where can I change the settings for disabling, or deleting local shared objects?” availableat <p>https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_
                                        settings_for_disabling__or
                                        _deleting_local_shared_objects_</p></li>
                                    <li><strong>Web Beacons.</strong> Certain sections of Our Platform and Our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count Visitors who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                                    <li><strong>Analytical Customization Cookies, Tracking Scripts and/or Pixels (“Tools”):</strong> The Tools collect information about how Visitors access and move through the Platform. We use this information in either aggregate form to help Us to improve the way Platform works, or to personalize your interests. For example, we use Google Analytics cookies, Facebook pixels and such similar Tools to help us understand how Visitorsarrive at and browse Our Platform and Platform to identify areas for improvement such as navigation, Visitor experience, and marketing campaigns. Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use these cookies, over which we have no control.</li>
                                    <li><strong>Advertising/Target Cookies</strong> These cookies are used to make the advertising displayed on the Platform more relevant to you. These cookies prevent ads reappearing and ensure that ads are properly displayed. Certain third party cookies may track Visitors over different websites to provide relevant ad of Your interests.</li>
                                    <li><strong>Social Media Cookies</strong> These cookies allow Us to integrate social media functions into Our Platform and may also be used for advertising purposes.</li>
                                </ul>

                                <p>Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: What Are Cookies?</p>

                                <div className="overflow-hidden">
                                <table>
                                    <tr>
                                        <td valign="top">Necessary Cookies</td>
                                        <td><p>These Cookies are essential to provide You with services available through the Platform and to enable You to use some of its features. They help to authenticate Visitors and prevent fraudulent use of Visitors Accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
                                            <br /><br />
                                            If you are a Visitor, they help us know who You are so that you can log on and manage your accounts. They also help us keep your details safe and private.</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Functional Cookies</td>
                                        <td>
                                            <p>These cookies are used for remembering things like:</p>
                                            <ul>
                                                <li>Your search</li>
                                                <li>Your region or country</li>
                                                <li>Your preferred language</li>
                                            </ul>
                                            <p>The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter Your preferences every time You use the Platform.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Performance Cookies</td>
                                        <td><p>These cookies tell us how You and our other Visitors use our Platform. We combine all this data together and study it. This helps us to:</p>
                                            <ul>
                                                <li>Improve the performance of our services</li>
                                                <li>Improve the products we provide</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Cookies Policy /</td>
                                        <td>
                                            <p>These Cookies identify if Visitors have accepted </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Notice Acceptance Cookies:</td>
                                        <td>
                                            <p>the use of cookies on the Website. </p>
                                        </td>
                                    </tr>
                                </table>
                                </div>


                                <p>You can adjust Your browser to disable cookies or notify You when they are set if You prefer. However, if You disable them, You might not be able to get the most out of Our Platform or parts of the Platform.</p>
                                <p>Third parties whose products or services are accessible or advertised through the Platform, including social media services, may also use cookies or similar tools, and we advise You to check their privacy policies for information about their cookies and other practices. We do not control the practices of such partners and their privacy policies govern their interactions with You.</p>

                                <h5 className="mt-4 d-block">3.	Use of Data Collected</h5>
                                <p>We may share Your personal information in the following situations:</p>

                                <ul>
                                    <li><strong>With Service Providers:</strong> We may share Your Personal Data with Service Providers to monitor and analyse the use of Our Platform. We may share Your information with outside vendors that We use for a variety of purposes, such as to send You communications via emails, messages or tele-call to inform you about Our Products (as defined in Our Terms of Use) that may be of interest to you, provide voice recognition services to process your spoken queries and questions, help Us analyse use of Our Platform, and process and collect payments. Some of Our Products and databases are hosted by third party hosting services providers. We also may use vendors for other projects, such as conducting surveys.</li>
                                    <li><strong>With Affiliates:</strong> We may share Your Personal Data  with Our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                                    <li><strong>Storage of Data:</strong> When You share Personal Data, You give Us a general authorisation to engage a third party data processor for assisting Us in storing Your data. We ensure that the third-party processor complies with all data protection obligations, which are not less onerous than Our data protection liability.</li>
                                    <li><strong>With Your consent:</strong> We may disclose Your Personal Data for any other purpose with Your consent. You agree to the above processing. We will never supply Your Personal Data to third parties unless under the conditions stated in Our Privacy Policy. Notwithstanding the foregoing, You acknowledge and agree that we may aggregate and anonymize You, Personal Data, and use and share such aggregated and anonymized Personal Data with third parties for statistical purposes and for the purpose of data analytics, product development, and/or Platform improvement. We may also use Your Personal Data to protect against and prevent fraud, claims, and other liabilities and to comply with or enforce applicable legal requirements, industry standards, and Our policies and terms. We use Personal Data for these purposes when it is necessary to protect, exercise, or defend Our legal rights, or when we are required to do so by applicable law.</li>
                                </ul>

                                <h4 className="mt-4 d-block"><strong>(D)</strong> RETENSION/STORING OF YOUR DATA</h4>
                                <p>We employ third party companies and individuals to help Us facilitate Our Platform by collecting/storing information under this Privacy Policy. This enables Us to provide Our Platform in addition to the data that is stored by Our third-party processors. Your data is maintained and archived on secure, hardened servers that are hosted on highly secure data centers.We will not retain Your information for longer than what required for the purposes for which the information is being collected, that is to provide Our Platform to You without any interruption.</p>
                                <p>The information We collect is only for the improved use of the Platform. We may retain Your information to provide Our facilities to You and to recognize Your future use of the Platform or to send You promotional emails and messages (SMS).</p>

                                <h4 className="mt-4 d-block"><strong>(E)</strong> TRANSFER OF YOUR PERSONAL DATA</h4>
                                <p>Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                                <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other Personal Data.</p>

                                <h4 className="mt-4 d-block"><strong>(F)</strong>	DISCLOSURE OF YOUR PERSONAL DATA</h4>

                                <h5 className="mt-4 d-block">1.	Corporate Partners</h5>
                                <p>We may share Your personal information with Our other corporate entities and affiliates. These entities and affiliates may market to You as a result of such sharing of Your Personal Data unless You explicitly opt-out as provided in our Privacy Policy.</p>

                                <h5 className="mt-4 d-block">2.	Business Transactions</h5>
                                <p>We and Our affiliates may share Your Personal Data with other business entity should We (or Our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur with other business entity (or the new combined entity) will be required to follow the same standards as the terms of Our Privacy Policy with respect to Your personal information.</p>

                                <h5 className="mt-4 d-block">3.	Law enforcement</h5>
                                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). We may also disclose Your Personal Data to comply with (a) Our legal obligation, (b) protect the rights of Our Company, (c) prevent or investigate possible wrongdoing in connection with the Platform, (d) protect the personal safety of Visitors of the Platform or the public; (e) protect against legal liability and to protect the rights of Our Visitors.</p>

                                <h4 className="mt-4 d-block"><strong>(G)</strong> SECURITY OF YOUR PERSONAL DATA</h4>
                                <p>We value, respect, and seek to protect Your privacy, and as a result, We have taken the necessary steps to ensure that We are in compliance with all applicable laws, including, but not limited to, the provisions of the Information Technology Act of 2000 read along with the Reasonable Security Practices And Procedures And Sensitive Personal Data Or Information) Rules, 2011 (the <strong>IT Act</strong>).</p>
                                <p>The type of information gathered and recorded by the Us, as well as how We utilise it, are detailed in this Privacy Policy. We've put in place reasonable security policies and procedures that are appropriate for the information assets and are in compliance with the international standard. While We do Our best to offer security that meets industry standards, We cannot guarantee or assure the total security of any information transmitted by You to Us.</p>

                                <h4 className="mt-4 d-block"><strong>(H)</strong> YOUR RIGHTS UNDER THIS PRIVACY POLICY</h4>
                                <p>You have certain rights with respect to the Personal Data We collect about You. The and the same are under:</p>
                                <ul>
                                    <li>right to access;</li>
                                    <li>the right to rectification;</li>
                                    <li>the right to erasure;</li>
                                    <li>the right to restrict processing;</li>
                                    <li>the right to object to processing;</li>
                                    <li>the right to data portability; and;</li>
                                    <li>the right to data portability; and.</li>
                                </ul>

                                <p>Upon Your request, We tell You what information We hold about You, We rectify any incomplete or inaccurate information and also restrict the use of Your information. We will make reasonable efforts to delete Your information if You ask Us to do so, unless We are otherwise required to keep it.</p>

                                <h4 className="mt-4 d-block"><strong>(I)</strong> CHILDREN’S PRIVACY</h4>
                                <p>We recognize the privacy interests of children and encourage parents and guardians to take an active role in their children’s online activities and interests. We do not address anyone under the age of 18 years.</p>
                                <p>We do not knowingly collect personally identifiable information from any individual Younger than the required age for obtaining consent or to access Our Platform and/or avail any of the Platform.  If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from an individual Younger than the required age without verification of parental consent, We take steps to remove that information from Our servers.</p>

                                <h4 className="mt-4 d-block"><strong>(J)</strong> LINKS TO OTHER/THIRD PARTY WEBSITES</h4>
                                <p>The Platform contains links to third-party/external websites including Our partners. The Platform is not responsible for the privacy policy or practices on such linked third-party/external sites. This Privacy Policy only governs information collected on the Platform. When linking to a third-party/external website, You must read the privacy policy stated on their website. Please be aware of their terms since Our Privacy Policy does not apply to such third-party/external websites. The Platform contains links to other external sites and resources provided by third parties for Your convenience and information only. We accept no liability in connection with any third-party/external website accessed via a link from the Platform, or any contract entered into or through a third-party/external website. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from Your use of them.</p>

                                <h4 className="mt-4 d-block"><strong>(K)</strong> YOUR CONSENT</h4>
                                <p>You consent to Our Privacy Policy and agree to its terms by using the Platform. If You wish to be informed about what Personal Data We hold about You and if You want it to be removed from Our systems, please email Us by email: Chandramohan@pumpacademy.in or By visiting this page on Our website: www.pumpacademy.in. Your data will be removed from Our system with prospective effect. Any action taken retrospectively will have no effect to Your withdrawal of Your consent.</p>

                                <h4 className="mt-4 d-block"><strong>(L)</strong> AMENDMENTS TO THIS POLICY</h4>
                                <p>If We decide to alter Our Privacy Policy, We will update this Privacy Policy to reflect the changes so You are always informed of what information We collect, how We use it, and under what circumstances, if any, We release it. We will use the information in line with the Privacy Policy that was used to obtain it. We urge You to examine Our policy on a regular basis for the most up-to-date information.</p>

                                <h4 className="mt-4 d-block"><strong>(M)</strong> COMPLAINTS AND GRIEVANCE REDRESSAL</h4>
                                <p>Any complaints or request or concerns with regards to use, processing or disclosure of information provided by You or breach of these terms may be taken up with the designated grievance redressal officer. For this purpose, We have designated Mr. Chandra Mohan M S as the grievance officer, who will redress the grievances of the Visitors expeditiously but within one month from the date of receipt of grievance, and who can be reached by:</p>

                                <p> (i)	Sending a letter marked to the attention of <br /> "Grievance Officer, Pump Academy Private Limited "<br /> 3rdFloor, Corporate Block, <br /> Tower B1, Golden Enclave, <br /> HAL, Old Airport Road, <br /> Bengaluru 560017.<br /> or  </p>
                                <p>Sending an email <a href="mailto:toChandramohan@pumpacademy.in">toChandramohan@pumpacademy.in</a></p>
                                <p>We will not be responsible for any communication, if addressed, to any non-designated person in this regard.</p>

                                <h4 className="mt-4 d-block"><strong>(N)</strong> HOW YOU CAN CONTACT US</h4>
                                <p>If You have any comments or questions about the collection, use or disclosure of Your Personal Data or this Privacy Policy, please contact Us in writing at the address below referencing ‘Privacy Policy’:</p>
                                <strong>Data Protection Officer</strong>
                                <p>Mr. Chandra Mohan M S<br />
                                    3rdFloor, Corporate Block,<br />
                                    Tower B1, Golden Enclave,<br />
                                    HAL, Old Airport Road,<br />
                                    Bengaluru 560017.
                                </p>

                                <p>Email: <a href="mailto:Chandramohan@pumpacademy.in">Chandramohan@pumpacademy.in</a></p>
                                <p>Telephone no: <a href="tel:8049411700">+91 80-49411700</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy;