import { Link } from "react-router-dom";

const GetTouch = () => {


    return (
        <>

            <div className="getaquotebt">
                <Link to='/contact-us' id="request_a_proposal_cofee_track_button">
                    <img src="images/phone_icon.webp" alt="Get in Touch" title="Get in Touch" />
                </Link>
            </div>

        </>
    )
}

export default GetTouch;