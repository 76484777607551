import { Link } from "react-router-dom";
import PumpPerformance from "./PumpPerformance";

const ApplicationBanner=()=>{


    return(
        <>
        <div className="applicationtitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="page-tit">
                                <h1>Application</h1>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Application</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PumpPerformance />
        </>
    )
}


export default ApplicationBanner;