const BenefitsIpumpnet = () => {


    return (
        <>
            <div className="benefits">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <h2 className="text-center mb-4 d-flex justify-content-center align-items-baseline benefits-logo">Advantages of using iPUMPNET</h2>
                            <ul>
                                <li>
                                    <img src="images/operational.webp" />
                                    <p>Improves the operational efficiency of pumping stations by between <span>5-35%*.</span></p>
                                </li>
                                <li>
                                    <img src="images/pumping-station.webp" />
                                    <p>Almost eliminates unscheduled breakdowns or maintenance.</p></li>
                                <li>
                                    <img src="images/reducers-lcc.webp" />
                                    <p>Provides real-time performance and operational information of pump sets.</p></li>

                                <li>
                                    <img src="images/maintainance.webp" />
                                    <p>Optimizes the operation of parallel/series pumping sets.</p></li>
                                <li>
                                    <img src="images/performance-icon.webp" />
                                    <p>Extends the life of pumping stations by up to <span>50%.</span></p></li>
                                <li>
                                    <img src="images/combination-operation.webp" />
                                    <p>Reduces the life cycle cost of pumping stations by <span>15-45%.</span></p></li>

                                <li>
                                    <img src="images/return-investment.webp" />
                                    <p>Offers a return on investment in most cases within a year.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p  className="text-end star-tag mt-3 define mb-0">* Subject to following our recommendations for operations and maintenance</p>
            </div>
        </>
    )
}

export default BenefitsIpumpnet;