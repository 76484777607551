import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ChapterOne = () => {

 


    return (
        <>

            <div className="knowledgetitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>knowledge Base</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Pump Academy - iPUMPNET</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pdf-sec mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-center mb-3">Pump Academy - <img className="ipump-logo" src="images/pumpnet-logo.webp" style={{'width': '80px', 'marginRight': '0px'}} /></h4>
                            <div className="pdf-knowledge">
                                <img src='images/knowledge/pump-academy-ipumpnet/pump-academy-ipumpnet2-mainone.webp' />
                                <img src='images/knowledge/pump-academy-ipumpnet/pump-academy-ipumpnet2-maintwo.webp' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default ChapterOne;