import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';



const AcademyWorks = () => {




    return (
        <>
            <div className="academy-works">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="academy-heading text-center">
                                <h2 className='mb-3'>How iPUMPNET Works</h2>
                                <p>Our Approach to Solve the Monitoring of Centrifugal Pumps</p>
                            </div>
                        </div>
                    </div>

                    <div id="gap-work" className="row">
                        <div className="col-md-12">
                            <div className="works-tabs">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="iiot" transition={true}>
                                    <Row>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="iiot">
                                                    <video autoPlay muted loop>
                                                        <source src="images/IIOT.mp4" type="video/mp4" />
                                                    </video>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="digital">
                                                <video autoPlay muted loop>
                                                        <source src="images/digital-connectivity.mp4" type="video/mp4" />
                                                    </video>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>

                                        <div className='tab-horizontal'>
                                            <Col sm={12}>
                                                <Nav variant="pills" className="flex-column">
                                                    <div className='back-wrap'>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="iiot">IIOT Architecture</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="digital">Digital Connectivity</Nav.Link>
                                                        </Nav.Item>
                                                    </div>
                                                </Nav>
                                            </Col>
                                        </div>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AcademyWorks;