import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import $ from "jquery";
import React, { useEffect } from "react";

const WeDeliver = () => {

    const Show=()=>{
        
        
    }

    
    
    return (
        <>
            <div className="we-deliver">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="deliver-heading">
                                <h2>Components of iPumpnet</h2>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="solution-tabs">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first"  transition={true}>
                                    <Row>
                                        <Col md={12} lg={4}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" onClick={Show}>
                                                        <h4>High Precision Web of IoT enabled sensors</h4>
                                                        <p>Specific precision sensors installed in each pump set for capturing various parameters</p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" onClick={Show}>
                                                        <h4>Hardware with <br />Embedded Analytics</h4>
                                                        <p>Auto Signal conditioner based 24-bit ADC ensuring almost zero signal delay</p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="three" onClick={Show}>
                                                        <h4>Secured Communication</h4>
                                                        <p>Bi-directional communication between cloud and multi-SIM based IoT gateway.</p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="four" onClick={Show}>
                                                        <h4>Hybrid Cloud and Mobile <br /> Application</h4>
                                                        <p>Deep learning based Artificial Intelligence (AI) tools with digital twin and HMI in a cloud platform environment</p></Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col id='ele' md={12}  lg={8}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <img className='img-fluid' src='images/precision-sensor-graph.webp' />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <img className='img-fluid' src='images/hardware-analysics.webp' />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="three">
                                                    <img className='img-fluid' src='images/secured-communication.webp' />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="four">
                                                    <img className='img-fluid' src='images/hybrid-cloud.webp' />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeDeliver;