import { Link } from 'react-router-dom';

const Press = () => {

    return (
        <>
            <div className="presstitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Press Release</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Media</li>
                                    <li><Link to="/Press">Press Release</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container media-sec mt-20">
                <div className="row no-gutters">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <ul className="list-group my-3">
                            <a href="/download/press/2023-2024/iPUMPNET Unveiled by the Union Minister of Jal Shakti & Company Wins ET Infra Focus Award 2024 for Best Brand in Water Technology.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        iPUMPNET Unveiled by the Union Minister of Jal Shakti & Company Wins ET Infra Focus Brand Award 2024
                                    </h5>
                                    <small>
                                        Bengaluru, September 2024
                                    </small>
                                </li>
                            </a>
                            <a href="/download/press/2023-2024/Pump-Academy-Private-Limited-Signed-MoU-with-JSS-Academy-of-Technical-Education.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        Pump Academy Private Limited Signed MoU with
                                        JSS Academy of Technical Education, Bengaluru
                                        for Industry-Academic Collaboration
                                    </h5>
                                    <small>
                                        Bengaluru, April 22, 2023
                                    </small>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Press;