


const DisclaimerContent=()=>{

    return(
        <>
          <div className="terms-conditions mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-content">
                                <h2>DISCLAIMER</h2>
                                <p>This website www.pumpacademy.in is the sole intellectual property of Pump Academy Private Limited (hereinafter referred as “Company”) and is owned by the Company. The information provided by Company on the website www.pumpacademy.in, (hereinafter referred to as “Platform”)is for general informational purposes only. All information on the Platform is provided in good faith, however, the Company make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Platform.</p>
                                <p>This disclaimer may change from time to time without any prior notice, and yet will always be applicable to the visitors of the Platform. Please get update on the Disclaimer by going through the disclaimer section on the platform before using the Platform.</p>
                                <p>Please note that this disclaimer is valid for this Platform and all social media activities of or on behalf of Pump Academy Private Limited. Hence, the usage Platform includes this website, facebook page, twitter page and any other page owned or managed or inspired by Pump Academy Private Limited. It also includes activities on whatsapp, quora, linkedin, youtube, google, and other social platforms as well as different forms of media content – text, audio, video, books, pamphlets, brochures that exist or may come up in future.</p>
                                <p>Visitors access to and use of this Platform is subject to the following terms and conditions and all applicable laws. By accessing and browsing this Platform, you accept, without limitation or qualification, these terms and conditions visitors acknowledge and agree to abide by these terms and conditions as laid down below:</p>

                                <ul>
                                    <li>The Company do not warrant the accuracy and completeness of the material on the Platform. The Company may make changes to the material from time to time, without notice. The material on the Platform may be out of date. Although Companystrives to constantly update the content and material, andshall not be liable for any delays in the same.</li>
                                    <li>The information contained in or made available through Platform (including but not limited to information contained on videos, audios, message boards, comments, on calls, in emails, in text files, or in chats) cannot replace or substitute for the services of trained professionals in any field, including, but not limited to, financial, medical, psychological, or legal matters.</li>
                                    <li>In particular, visitors should regularly consult a professional and manufacturer in matters relating to the diagnosis of the pumping station or any matter arising out of the malfunctioning of the pumping station.</li>
                                    <li>Under no circumstances shall the Company be responsible for any loss or damage, resulting from use of information provided on the Platform, from any content posted on the Platform, or from the conduct of any visitors, whether online or offline, or damage arising as a result of any bugs, Trojan horses, viruses, worms or other harmful codes or errors.</li>
                                    <li>Company makes no warranties or guarantees that the use of the Platform and the information availed by Platform visitors and visitors fully agree that there are no guarantees as to the specific outcome or results you can expect from using the information you receive on the Platform. The informationprovided on the Platform are intended for the benefit of the general public and are not intended to replace or substitute advice from a qualified professional.</li>
                                    <li>To the fullest extent permitted by applicable law, the Company shall not be liable to any visitorsaccessing or browsing the Platform, for any tort, equity or otherwise for any loss or damages arising out of its use of the Platform or by utilising the information provided on the Platform, whether any of the foregoing are, without limitation, special, incidental, indirect, punitive or consequential and/or whether any of the foregoing are, without limitation, occasioned by the negligence, fault, error, omission, act, by visitors. Without prejudice to the generality of the foregoing, under no circumstances shall Company be liable to any visitor for any consequential, economic or indirect loss or damages, loss of profits, revenue, business, capital, administrative time or loss of use of data or software, however arising.</li>
                                    <li>Part of Platform may contain advertising and sponsorship. Advertising and sponsorship are responsible for ensuring that material submitted. Companytakes no responsibility for third-party advertisements that are posted on Platform and shall not be responsible for any error or inaccuracy in the advertising materials.</li>
                                    <li>The Company assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any visitor communication.</li>
                                    <li>Commentary and other materials posted on this Platform are not intended to amount to advice on which reliance should be placed. The Company therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any visitor to the Platform, or by anyone who may be informed of any of its contents.</li>
                                    <li>Although Company may from time to time monitor or review postings, transmissions, bulletin boards, and the like on the Platform, Companyis under no obligation to do so and assume no responsibility or liability arising from the content of any such locations nor for any error, defamation, libel, slander, omission, falsehood, obscenity, pornography, profanity, danger, or inaccuracy contained in any information within such locations on the Platform. Visitors are prohibited from posting or transmitting any unlawful, threatening, libellous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law.</li>
                                    <li>Visitors acknowledge that they are participating voluntarily in using the Platform and that they are solely and personally responsible for their choices, actions and results, now and in the future. Visitors accept full responsibility for the consequences of their use, or non-use, of any information provided on or through this Platform, and agree to use their own judgment and due diligence before implementing any idea, suggestion or recommendation from Platform and the information to their business or usage.</li>
                                    <li>You hereby fully and completely agree to hold harmless, indemnify and release the Company and any of agents, professionals, service providers, affiliates, joint venture partners, employees, shareholders, directors, staff, team members, or anyone otherwise affiliated with our organization from any and all causes of action, allegations, suits, claims, damages, or demands whatsoever, in law or equity, that may arise in the past, present or future that is in any way related to the usage of the Platform.</li>
                                    <li>Although every effort is made to ensure the accuracy of information shared on or through this Platform, the information may inadvertently contain inaccuracies or typographical errors. Visitors agree that the Company is not responsible for the views, opinions, or accuracy of facts referenced on or through the Platform, or of those of any other individual or company affiliated with the Platform in any way. Because scientific, technology and business practices are constantly evolving, you agree that Companyis not responsible for the accuracy of the Platform, or for any errors or omissions that may occur.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DisclaimerContent;