import { Link } from "react-router-dom";
import ListPoint from "./ListPoint";


const CredentialsBanner=()=>{


    return(
        <>
          <div className="credentialstitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>Credentials</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Credentials</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ListPoint />
        </>
    )
}

export default CredentialsBanner;