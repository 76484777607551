const PumpPerformance = () => {


    return (
        <>

            <div className="pumpperformance">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        
                            <h2 className="find-your-app">Find Your Application</h2>
                            <h2 className="mb-5 text-center">End to end Integrated Pump Performance Management Solution for</h2>
                             
                            <div className="find-application">
                                <ul>
                                    <li>
                                        <img src="images/large-pipe.webp" />
                                        <div className="clearfix"></div>
                                        <span>Drinking water supply, waste water and Irrigation.</span>
                                    </li>
                                    <li>
                                        <img src="images/power-generation-unit.webp" />
                                        <div className="clearfix"></div>
                                        <span>Power Generation Units.</span>
                                    </li>
                                    <li>
                                        <img src="images/air.webp" />
                                        <div className="clearfix"></div>
                                        <span>Air Conditioning System.</span>
                                    </li>
                                    <li>
                                        <img src="images/paper-pulp.webp" />
                                        <div className="clearfix"></div>
                                        <span>Paper and pulp industry.</span>
                                    </li>
                                    <li>
                                        <img src="images/petrochemical-industries.webp" />
                                        <div className="clearfix"></div>
                                        <span>Petro chemicals industry.</span>
                                    </li>
                                    <li>
                                        <img src="images/marine-operations.webp" />
                                        <div className="clearfix"></div>
                                        <span>De-salination units.</span>
                                    </li>

                                    <li>
                                        <img src="images/mining-img.webp" />
                                        <div className="clearfix"></div>
                                        <span>Mining industry.</span>
                                    </li>

                                    <li>
                                        <img src="images/marine-app.webp" />
                                        <div className="clearfix"></div>
                                        <span>Marine Operations.</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PumpPerformance;