import Banner from './Banner';
import PrecisionMonitoring from './PrecisionMonitoring';
import WeDeliver from './WeDeliver';
import DeployFaster from './DeployFaster';
import AcademyWorks from './AcademyWorks';
import Monitored from './Monitored';
import TrustedBy from './TrustedBy';
import Articles from './Articles';
import ScrollToTop from './BackTop';
import BenefitsIpumpnet from './BenefitsIpumpnet';
import IpumpnetExclusivity from './IpumpnetExclusivity';


const Home=()=>{
    
    
    return(
        <>
        <Banner />
        <DeployFaster />
        <BenefitsIpumpnet />
        <PrecisionMonitoring />
        <WeDeliver />
        <AcademyWorks />
        <Monitored />
        <IpumpnetExclusivity/>
        <ScrollToTop />
        </>
    )
}

export default Home;