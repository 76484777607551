import { Link } from "react-router-dom";

const TermsContent = () => {


    return (
        <>
            <div className="terms-conditions mt-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-content">
                                <h2>TERMS AND CONDITIONS</h2>
                                <p>Pump Academy Private Limited is a private limited company with its registered office at D Block-1006, IttinaAkkala, Hoodi Circle, Rajapalya, Bangalore – 560048 (Karnataka) India(“Company”, “We”, “Us”, “Our” and terms of similar meaning) and engaged in the provision of services of deep learning based Integrated Pumping Station Performance Management Solution (IPMS) (“Services”). The Website Terms and Conditions (“Terms of Use”) shall apply only to the visitors who visit the website <Link to="/">www.pumpacademy.in</Link> (“Platform”). For the purposes of these Terms, the visitors shall together hereinafter be referred individually to as “You”, “Your” or “Yourself” (collectively referred to herein as “Visitors”).</p>
                                <p>The Platform provides services of deep learning based Integrated Pumping Station Performance Management Solution (IPMS) (“Services”).</p>
                                <p>By visiting or accessing any part of the Platform the Visitor, agree to be bound by these Terms of Use contained herein and by other policies of the Company (“Policies”) as posted on the Platform from time to time.</p>
                                <p>We act as merely act as Service Provider(s). If you are not prepared to agree to all these Terms of Use and our existing Policies including changes published from time to time, then You must cease accessing Our Platform with immediate effect and delete any materials that You may have downloaded or copied from the Platform. These Terms of Use govern Your use of the Platform and constitute a legal agreement between You and Us.</p>
                                <p>These "Terms of Use" constitute an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                                <p>As a Visitor of the Platform, the collection, use and sharing of your personal data is subject to Our Policies.</p>

                                <h3 className="mt-4 d-block">1. Eligibility</h3>
                                <p>The visitor visiting the Platform shall be such persons who can form legally binding contracts under. Persons who are 'incompetent to contract' within the meaning of the Indian Contract Act, 1872 including minors, persons of unsound mind, and undischarged insolvents are not eligible to browse the Platform. A minor is not allowed to access the platform or schedule a demo. You must be of Minimum Age. “Minimum Age” means 18 years old. Any minor, if at all, accessing our Platform shall be under the supervision of their guardians. The Company or its associates do not knowingly collect information from minors.</p>

                                <h3 className="mt-4 d-block">2. Update and/or Change in Terms.</h3>
                                <p>These Terms are subject to periodic change and/or updates. We are not bound to provide notice of such changes or updates. We reserve the right to make changes or updates at any time and, hereby, You agree to be bound by such changes prospectively if You continue to use our Services after the updates/change. We suggest to our Visitors to please check these Terms periodically to update themselves on any changes. Your continued use of the Platform following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform.</p>

                                <h3 className="mt-4 d-block">3. Product & Services Information</h3>
                                <p>The Company attempts to be as accurate as possible in the description of the Service on the Platform. However, the Company does not warrant that the Service description, information or other content of the Platform is accurate, complete, reliable, current or error-free. The Site may contain typographical errors or inaccuracies and may not be complete or current. The Company does not implicitly or explicitly support or endorse the sale or purchase of any Products or Services on the Platform. The Company accepts no liability for any errors or omissions, whether on behalf of itself or third parties. The Company reserves the right to correct, change or update information, errors, inaccuracies or omissions at any time (including after an order has been submitted ) without prior notice. Please note that such errors, inaccuracies or omissions may also relate to pricing and availability of the product or services.</p>

                                <h3 className="mt-4 d-block">4. Use of the Platform/Website</h3>
                                <ul>
                                    <li>You may not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform/Website or any content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform/Website or any content. We reserve the right to bar any such activity.</li>
                                    <li>You may not attempt to gain unauthorized access to any portion or feature of the Platform/Website, or any other systems or networks connected to the Platform/Website or Services offered on or through the Platform/Website, by hacking, password "mining" or any other illegitimate means.</li>
                                    <li>You may not probe, scan or test the vulnerability of the Platform/Website or any network connected to the Platform/Website, nor breach the security or authentication measures on the Platform/Website or any network connected to the Site. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Platform/Website.</li>
                                    <li>You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of Platform/Website or networks, or any systems or networks connected to the Platform/Website.</li>
                                    <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Site or any transaction being conducted on the Site, or with any other person’s use of the Site.</li>
                                    <li>You may not use the Site or any Content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes Our rights.</li>
                                </ul>


                                <h3 className="mt-4 d-block">5. Other Terms and Conditions</h3>
                                <p>Additional terms and conditions may apply to specific portions or features of the Platform, including  promotions or other similar features, all of which terms are made a part of these Terms of Use by this reference. You agree to abide by such other terms and conditions, including where applicable representing that you are of sufficient legal age to use or participate in such service or feature. If there is a conflict between these Terms of Use and the terms posted for or applicable to a specific portion of thePlatform and/or Services offered on or through thePlatform, the latter terms shall control with respect to your use of that portion of the Site or the specific service. Our obligations, if any, with regard to Our products and Services are governed solely by the agreements pursuant to which they are provided, and nothing on this Platform should be construed to alter such agreements. We may make changes to any products or Services offered or to the applicable prices for any such products or Services, at any time, without notice.</p>

                                <h3 className="mt-4 d-block">6. Visitor information on the Platform, Accounts, Password and Security.</h3>
                                <p>Certain features or services offered on or through the Website/Platform may require you to sign-up by providing information and creating passwords and also the same is required at the time of subscription to any of Services and/or products. You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You agree to notify the Company immediately of any unauthorized use of your account or password, or any other breach of security. We will not be held liable for and losses incurred as a result of Your failing to keep your account information secure and confidential. You represent and warrant that you are the owner of and/or otherwise have the right to provide all information, and/or otherwise make available to Us through the Platform or otherwise in connection with your use of our Platform Services.</p>

                                <h3 className="mt-4 d-block">7. Our Intellectual Property and Company Content:</h3>
                                <p>We retain ownership of all intellectual property rights related to the provisioning of the Website, Our Platform and Services and related icons and logos whether registered or unregistered are the trademarks of the Company and are protected under applicable copyright, trademark, and other proprietary and intellectual property laws. Visitors unauthorized adoption copying, modification, use or publication of these marks is strictly prohibited.</p>
                                <p>For the purpose of these Terms of Use "Company Content" means content that Company creates and make available in connection with the Services including, but not limited to, visual interfaces, interactive features, graphics, design, compilation, computer code, products, software, aggregate ratings, reports and other usage-related data in connection with activities associated with your account and all other elements and components of the Services excluding Your Content and Third Party Content. "Third Party Content" means content that comes from parties other than Company or its Customers and is available on the Services.</p>

                                <h3 className="mt-4 d-block">8. Third Party Content</h3>
                                <p>The Platform displays and/or refers to links to other websites from time to time. Company: (i) does not endorse or take responsibility for the content of such websites, (ii) is not responsible for the availability of such websites; and (ii) will not be liable in any way for any loss or damage which you may suffer by using such websites. If you decide to access linked websites, you do so at your own risk. You acknowledge that when You access a link that leaves the Platform, the site you will enter into is not controlled by us and different terms of use, Privacy Policy and other policies may apply. We reserve the right to disable links to and / or from third-party sites to the Platform, although We are under no obligation to do so.</p>

                                <h3 className="mt-4 d-block">9. Termination</h3>
                                <p>We reserve the right, in Our sole discretion, to restrict, suspend, or terminate this Policy and your access to all or any part of the Platform Services, at any time and for any reason without prior notice or liability. We reserve the right to change, suspend, or discontinue all or any part of the Platform Services at any time without prior notice or liability.</p>

                                <h3 className="mt-4 d-block">10. Disclaimer</h3>
                                <ul>
                                    <li>The Platform and its contents is presented on an "as is" and “as available” basis.</li>
                                    <li>You agree that, to the fullest extent permitted by applicable law, neither we nor our holding, subsidiaries, affiliates, partners, or licensors will be responsible or liable (whether in contract, tort (including negligence) or otherwise) under any circumstances for any <b>(i)</b> interruption of the Platform <b>(ii)</b> access delays or access interruptions to the Platform; <b>(iii)</b> loss or damages of any sort incurred as a result of dealings with or the presence of off-website links on the site; <b>(v)</b> viruses, system failures or malfunctions which may occur in connection with your use of the Platform, including during hyperlink to or from third party websites; <b>(vi)</b> any inaccuracies or omissions in content; or <b>(vii)</b> events beyond the reasonable control of the Company. We make no representations or warranties that defects or errors will be corrected.</li>
                                    <li>You agree that no claims or action arising out of, or related to, the use of the Platform or these terms and conditions may be brought by you more than one (1) year after the cause of action relating to such claim or action arose. if you have a dispute with us or are dissatisfied with the site, termination of your use of the site is your sole remedy. We have no other obligation, liability, or responsibility to you.</li>
                                </ul>

                                <h3 className="mt-4 d-block">11. Limitation of Liability</h3>
                                <p>Except where prohibited by law, in no event will the Company be liable to you for any indirect, consequential, exemplary, incidental or punitive damages, including lost profits, even if it has been advised of the possibility of such damages.</p>

                                <h3 className="mt-4 d-block">II. MISCELLANEOUS</h3>
                                <ul>
                                    <li><b>Waiver and Severability:</b> Our failure to enforce any right or provision of these Terms of Use will not be deemed a waiver of such right or provision.</li>
                                    <li><b>Law and Jurisdiction:</b> These Terms of Use and any action related thereto will be governed by the laws of India, without regard to or application of its conflict of law provisions or those of your state or country of residence. All claims, legal proceedings or litigation arising in connection with the Platform Services will be brought solely in the courts of Bangalore, Karnataka, India. You consent to this jurisdiction and waive any objection as to an inconvenient forum.</li>
                                    <li><b>Superseding:</b> These Terms of Use are the entire and exclusive agreement between Us and you regarding the Services and these Terms of Use supersede and replace any prior agreements between Us and you regarding the Services. No other person or company will be third party beneficiaries to the Terms of Use.</li>
                                    <li><b>Assignment:</b> These Terms of Use and any rights and licenses granted hereunder, may not be transferred or assigned by You, but may be assigned by Us without restriction. Any assignment attempted to be made in violation of these Terms of Use shall be null and void.</li>
                                    <li><b>Notices:</b> We may send You notices by the email that You provide to Us. We have no responsibility for notices not delivered due to outdated or inaccurate contact information. If Your contact information is out of date, You may miss out on important notices. Please address Your notices to Mr. Chandra Mohan M Sat Pump Academy Private Limited, D Block-1006, IttinaAkkala, Hoodi Circle, Rajapalya, Bangalore – 560048 (Karnataka) INDIA</li>
                                    <li>Survival. You acknowledge that Your representations and warranties, obligations and the clauses relating to indemnities, limitation of liability, intellectual property rights, governing law and confidentiality shall survive the efflux of time and the termination of these Terms of Use.</li>
                                    <li>Entire Agreement. These Terms of Use together with Company’s Privacy Policy, other policies  and any other legal notices, communications published by the Company on its Platform, and any other agreements executed between You and  the Company shall constitute the entire agreement between you and Company concerning its Platform, the Platform Services and governs Your use of the Platform and Platform Services, superseding any prior agreements between You and the Company with respect to the Platform and Platform Services</li>
                                    <li>Severability. In the event any provision of these Terms or Our other Policies, is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Use, such determination shall not affect the validity and enforceability of any other remaining provisions.</li>
                                </ul>

                                <h3 className="mt-4 d-block">How You Can Contact Us</h3>
                                <p>If You have any grievances and/or feedback and/or queries, please contact Us in writing at the address below referencing ‘Grievance of Policy’:</p>
                                <p> <i>Officer<br /> Mr. Chandra Mohan M S<br /> Pump Academy Private Limited, <br /> D Block-1006, IttinaAkkala, <br /> Hoodi Circle, Rajapalya, <br /> Bangalore-560048,<br /> Karnataka, India. </i></p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsContent;