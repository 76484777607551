const TechnologyPhara=()=>{


    return(
        <>
           <div className="iiot-manage">
            <div className="container">
                <div className="row">
                    
                    <div className="col-md-12 col-lg-7">
                        <div className="iiot-based">
                            <h2>An AI & IIoT-Based Precision Monitoring System for Optimized performance of Pumping Stations.</h2>
                            <p>The iPUMPNET is a powerful robost  and well-constructed remote management platform for data acquisition, analysis, and human-machine interface (HMI), creating a fully integrated solution.</p>
                            <p>iPumpNet is a machine learning-based Artificial intelligence enabled condition monitoring solution that uses digital twin representation for pump monitoring. It is a one-stop-shop for the entire product and solution, including sensors, precision analog-to-digital converters (ADC), operating system and browser independent hybrid cloud, and browser-independent mobile applications. The product is integrated, interoperable, and can be reconfigured with retrofit features to work with other system technologies such as PLC-based SCADA, Industry 4.0 and 5.0 digitizing factory solutions.</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5">
                        <img src="images/IIOT.webp" className="img-fluid" />
                    </div>
                </div>
            </div>
           </div> 
        </>
    )
}

export default TechnologyPhara;